/* eslint-disable react/no-array-index-key */
import React from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';

const Table = styled.table`
  width: 100%;

  > thead th {
    color: ${theme('colors.gray400')};
  }

  > tbody > tr > td {
    text-align: center;
    font-family: 'Roboto', sans-serif;
  }
`;

export default ({ cols, rows, emptyTable }) => {
  if (!rows.length) {
    return emptyTable;
  }

  return (
    <Table cellPadding="16">
      <thead>
        <tr>
          {cols.map((col, i) => (
            <th className={col.className} key={i}>
              {col.translation}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, i) => (
          <tr key={i}>
            {cols.map((col, j) => (
              <td className={col.className} key={`${i}${j}`}>
                {row[col.id]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
