import getConfig from 'next/config';
import Router from 'next/router';
import { saveAs } from 'file-saver';
import axios from 'axios';

import { parse } from 'utils';
import { DOCUMENT_DOWNLOAD_URL, DOCUMENT_SEARCH_URL } from 'utils/endpoints';

import * as actions from './actions';
import * as selectors from './selectors';
import { actions as notificationCardActions } from 'store/notification-card-deprecated';
import { selectors as userSessionSelectors } from 'store/user-session';
import api from 'utils/api';

const { publicRuntimeConfig } = getConfig();

export const downloadXml = () => async (dispatch, getState) => {
  dispatch(actions.toggleGeneratingBlobXml());

  const state = getState();
  const url = parse(DOCUMENT_DOWNLOAD_URL, {
    requestId: selectors.getNfeRequestId(state),
    type: 'xml',
  });

  try {
    const { data } = await axios({
      method: 'GET',
      responseType: 'blob',
      url,
    });

    const blob = new Blob([data], { type: 'application/xml' });
    saveAs(blob, `${selectors.getNfeAccessKey(state)}.xml`);
  } catch (error) {
    dispatch(notificationCardActions.setNotificationCardContext('try-again'));
    dispatch(notificationCardActions.toggleNotificationCard());
    console.error(error);
  } finally {
    dispatch(actions.toggleGeneratingBlobXml());
  }
};

const redirectToErrorPage = (errorCode) => {
  switch (errorCode) {
    case 'LITE_FREE_TIER_ERROR':
      Router.push('/limit-error/global');
      return;
    case 'LITE_SEARCH_TOO_MANY_REQUESTS_PER_USER':
      Router.push('/limit-error/user');
      return;
    default:
      Router.push('/error');
  }
};

const handleFetchNfeError = (dispatch, { response }) => {
  let notificationCardContext = 'try-again';
  let trackError = 'generic-error';

  const status = response && response.status;

  if (status === 429) {
    const {
      data: { code },
    } = response;
    redirectToErrorPage(code);
    return code;
  }

  if (status === 400) {
    notificationCardContext = 'home-not-found';
    trackError = 'home-not-found';
  }

  dispatch(
    notificationCardActions.setNotificationCardContext(notificationCardContext),
  );
  dispatch(notificationCardActions.toggleNotificationCard());
  return trackError;
};

export const fetchNfe = (trackSuccess, trackFailure) => async (
  dispatch,
  getState,
) => {
  dispatch(actions.toggleFetchingNfe());

  const state = getState();
  const nfeAccessKey = selectors.getNfeAccessKey(state);
  const userIp = userSessionSelectors.getUserIp(state);
  const userCookies = userSessionSelectors.getUserCookies(state);

  const payload = {
    accessKey: nfeAccessKey,
    gaCookie: userCookies,
    ip: userIp,
  };

  try {
    const { data } = await api({
      data: payload,
      method: 'POST',
      url: DOCUMENT_SEARCH_URL,
    });

    const { data: { requestId } = {} } = data;
    dispatch(actions.setNfeRequestId(requestId));

    dispatch(
      notificationCardActions.setNotificationCardContext('home-success'),
    );
    dispatch(notificationCardActions.toggleNotificationCard());

    trackSuccess();
  } catch (error) {
    const trackError = handleFetchNfeError(dispatch, error);
    trackFailure(trackError);
    console.error(error);
  } finally {
    dispatch(actions.toggleFetchingNfe());
  }
};

export const handleResetSingleKeyQuery = () => (dispatch, getState) => {
  const state = getState();
  const googleRecaptchaWidgetId = selectors.getGoogleRecaptchaWidgetId(state);

  dispatch(actions.resetSingleKeyQuery());

  if (googleRecaptchaWidgetId !== '') {
    dispatch(actions.setGoogleRecaptchaWidgetId(googleRecaptchaWidgetId));
  }
};

export const initializeGoogleRecaptcha = () => async (dispatch, getState) => {
  const state = getState();
  const googleRecaptchaWidgetId = selectors.getGoogleRecaptchaWidgetId(state);

  if (googleRecaptchaWidgetId !== '') {
    await window.grecaptcha.reset(googleRecaptchaWidgetId);
    dispatch(actions.setGoogleRecaptchaToken(''));
    return;
  }

  try {
    if (window.grecaptcha) {
      await new Promise((resolve) => window.grecaptcha.ready(() => resolve()));
      const widgetId = await window.grecaptcha.render('google-recaptcha', {
        sitekey: publicRuntimeConfig.GOOGLE_RECAPTCHA_KEY,
        callback: (token) => dispatch(actions.setGoogleRecaptchaToken(token)),
      });

      dispatch(actions.setGoogleRecaptchaWidgetId(widgetId));
    } else {
      setTimeout(() => {
        dispatch(initializeGoogleRecaptcha());
      }, 200);
    }
  } catch (error) {
    console.error(error);
  }
};
