import React from 'react';
import styled from 'styled-components';

import Header from './Header';
import { Footer, HiddenContent } from 'layouts/components/molecules';
import { Content } from 'layouts/components/atoms';

const WrapperSessionScreen = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SessionScreen = ({ className, children, logoAlignment }) => (
  <WrapperSessionScreen id="arquivei-lite" className={className}>
    <Header logoAlignment={logoAlignment} />
    <Content>{children}</Content>
    <Footer />
    <HiddenContent />
  </WrapperSessionScreen>
);

export default SessionScreen;
