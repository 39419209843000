import React from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';

const Wrapper = styled.div`
  background-color: ${theme('colors.gray100')};
  color: #000;
  padding: 48px !important;
  border: 10px solid transparent;
  border-top: none;
  border-image: url(/static/receipt-border.png) 30 stretch;

  width: 100%;
  max-width: 246px;
  min-height: 196px;

  .item-row {
    display: flex;
    justify-content: space-between;
    margin: 4px 0;
  }
  .title {
    font-size: ${theme('text.body.fontSize')};
    text-align: center;
    margin-bottom: 24px;
  }

  .description {
    color: ${theme('colors.gray400')};
    font-size: ${theme('text.cta.fontSize')};
  }

  .value {
    color: ${theme('colors.dark')};
    font-size: ${theme('text.cta.fontSize')};
    font-weight: normal;
  }

  table {
    border-collapse: separate;
    border-spacing: 0 10px;
    width: 100%;

    thead tr th {
      font-size: 14px;
      font-weight: bold;
      color: #a7a7a7;
      text-align: right;

      &:first-of-type {
        text-align: left;
      }
    }

    tbody tr td {
      font-size: 14px;
      text-align: right;
      color: #001634;
      font-weight: normal;

      &:first-of-type {
        text-align: left;
      }

      &:last-of-type {
        font-weight: 700;
      }
    }

    &.amount tbody tr td:last-of-type {
      font-size: 22px;
      font-weight: bold;
      text-align: right;
      color: #1f3a7c;
    }
  }

  hr {
    margin: 8px 0;
    border: dashed 1px #d8d8d8;
  }
`;

const Receipt = ({
  nominalPrice = 0,
  promotionPrice,
  promotionValue,
  nominalValue = 0,
}) => (
  <Wrapper>
    <div className="title">Detalhes da sua compra</div>
    <table>
      <thead>
        <tr>
          <th>Chaves</th>
          <th>Valor unit.</th>
          <th>Valor total</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{promotionValue || 0}</td>
          <td>
            R$
            {promotionPrice.toFixed(2).toString().replace('.', ',')}
          </td>
          <td>
            R$
            {Number(promotionValue * promotionPrice)
              .toFixed(2)
              .toString()
              .replace('.', ',')}
          </td>
        </tr>
        {nominalValue ? (
          <tr>
            <td>{nominalValue || 0}</td>
            <td>
              R$
              {nominalPrice.toFixed(2).toString().replace('.', ',')}
            </td>
            <td>
              R$
              {Number(nominalValue * nominalPrice)
                .toFixed(2)
                .toString()
                .replace('.', ',')}
            </td>
          </tr>
        ) : (
          false
        )}
      </tbody>
    </table>

    <hr />

    <table className="amount">
      <thead>
        <tr>
          <th>Total de chaves</th>
          <th>Total a pagar</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{nominalValue + promotionValue}</td>
          <td>
            R$
            {Number(
              nominalValue * nominalPrice + promotionValue * promotionPrice,
            )
              .toFixed(2)
              .toString()
              .replace('.', ',')}
          </td>
        </tr>
      </tbody>
    </table>
  </Wrapper>
);

export default Receipt;
