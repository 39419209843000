const getState = (state) => state.singleKeyQuery;

export const getGoogleRecaptchaToken = (state) =>
  getState(state).get('googleRecaptchaToken');
export const getGoogleRecaptchaWidgetId = (state) =>
  getState(state).get('googleRecaptchaWidgetId');
export const getIsFetchingNfe = (state) => getState(state).get('isFetchingNfe');
export const getIsGeneratingBlobXml = (state) =>
  getState(state).get('isGeneratingBlobXml');
export const getNfeAccessKey = (state) => getState(state).get('nfeAccessKey');
export const getNfeRequestId = (state) => getState(state).get('nfeRequestId');
