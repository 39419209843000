import axios from 'axios';
import crypto from 'crypto';

import { IP_TRACKING_URL } from 'utils/endpoints';

import * as actions from './actions';
import * as selectors from './selectors';

const generateUserId = () => {
  const currentDate = new Date().valueOf().toString();
  const random = Math.random().toString();
  return `VISITOR_${crypto
    .createHash('sha1')
    .update(currentDate + random)
    .digest('hex')}`;
};

/**
 * When `signedIn`, `token` and  `decodedToken` are not all set at the same
 * time, we _could_ reset the session since some unexpected behavior has
 * happened. However, a more "defensive" approach is to set corresponding
 * values when they exist, disregarding those previously in the store.
 */
export const syncUserSession = (req) => (dispatch, getState) => {
  if (!req) {
    return;
  }

  const { session: { signedIn, decodedToken, token, authorized } = {} } = req;

  if (signedIn && Object.keys(decodedToken).length && token) {
    const { uid = '', exp } = decodedToken;

    dispatch(actions.signIn());
    dispatch(actions.setUserId(uid));
    dispatch(actions.setSessionId(token));
    dispatch(actions.setTokenExpiresDate(exp));
  }

  if (authorized) {
    dispatch(actions.authorize());
  }

  if (!selectors.getUserId(getState())) {
    dispatch(actions.setUserId(generateUserId()));
  }
};

const trackUserIp = async () => {
  try {
    const {
      data: { ip },
    } = await axios({
      method: 'GET',
      url: IP_TRACKING_URL,
    });
    return ip;
  } catch (error) {
    console.error(error);
    return '';
  }
};

const trackUserCookies = () => {
  const { cookie } = document;
  const cookies = decodeURIComponent(cookie).split(';');

  const gaCookies = cookies.find(
    (c) =>
      c.indexOf('_ga=') === 0 ||
      (c.charAt(0) === ' ' && c.indexOf('_ga=') === 1),
  );
  if (gaCookies === undefined) {
    return '';
  }

  const userCookies = gaCookies.substring(4, gaCookies.length);
  return userCookies;
};

export const trackUserInfo = () => async (dispatch, getState) => {
  const state = getState();

  if (selectors.getUserIp(state) !== '') {
    return;
  }

  const userIp = await trackUserIp();
  dispatch(actions.setUserIp(userIp));

  const userCookies = trackUserCookies();
  dispatch(actions.setUserCookies(userCookies));
};
