import React from 'react';

import { NotificationCard } from 'components';

const HiddenContent = () => (
  <div id="arquivei-hidden">
    <NotificationCard />
  </div>
);

export default HiddenContent;
