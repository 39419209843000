import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

export const DOCUMENT_LIST_URL = '/document/list';
export const PURCHASE_LIST_URL = '/user/purchase-history';
export const FETCH_CREDITS = '/user/limit';
export const DOCUMENT_MULTI_SEARCH_URL = '/document/multi-search';
export const PURCHASE_CREDITS_URL = '/purchase';
export const ZIP_DOWNLOAD_URL = `${publicRuntimeConfig.API_URL}/document/download/%{requestId}/zip`;
export const PAGSEGURO_CHECKOUT = `https://${publicRuntimeConfig.PAGSEGURO_ENVIRONMENT}pagseguro.uol.com.br/v2/checkout/payment.html?code=%{code}`;
