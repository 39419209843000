import React from 'react';
import { withTracking } from 'react-tracker';
import { Button } from '../../Button';
import Content from './_style';

import { Heading } from '../../_styled';
import externalArquiveiEvent from '../../../utils/tracking/events/externalArquivei';

const EXTERNAL_URL_CTE = 'https://arquivei.com.br/consultar-cte/';

const HomeIsCte = ({ onClose, trackExternalArquiveiClick }) => (
  <Content>
    <div>
      <Heading.Title bold>
        A partir de agora a SEFAZ permite a consulta de CTes apenas com
        Certificado Digital
      </Heading.Title>
    </div>
    <Button
      href={EXTERNAL_URL_CTE}
      tracking={() => trackExternalArquiveiClick('/', 'Home', EXTERNAL_URL_CTE)}
      type="success"
    >
      SABER MAIS
    </Button>
    <Button onClick={onClose}>VOLTAR</Button>
  </Content>
);

const mapTrackingToProps = (trackEvent) => ({
  trackExternalArquiveiClick: (pagePath, pageTitle, destinationPage) => {
    trackEvent(externalArquiveiEvent(pagePath, pageTitle, destinationPage));
  },
});

export default withTracking(mapTrackingToProps)(HomeIsCte);
