import events from '..';

export default function (pagePath, pageTitle) {
  return {
    type: events.singleConsultDownloadXml,
    data: {
      event: events.singleConsultDownloadXml,
      pagePath,
      pageTitle,
    },
  };
}
