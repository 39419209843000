import React from 'react';
import { Button } from '../../Button';
import Content from './_style';

import { Heading } from '../../_styled';

export default ({ onClose }) => (
  <Content>
    <div>
      <Heading.Title>Ops...</Heading.Title>
      <Heading.Title bold>Você ainda não confirmou seu e-mail</Heading.Title>
    </div>
    <Button onClick={onClose}>ENTENDI</Button>
  </Content>
);
