import React from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Loading = styled(FontAwesomeIcon)`
  color: ${theme('colors.dark')};
`;

export default ({ className = '', size = '2x', ...props }) => (
  <Loading
    icon="circle-notch"
    size={size}
    spin
    className={className}
    {...props}
  />
);
