import React from 'react';
import styled from 'styled-components';

import Header from './Header';
import { Footer, HiddenContent } from 'layouts/components/molecules';
import { Content } from 'layouts/components/atoms';

const WrapperDefaultScreen = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const DefaultScreen = ({ children }) => (
  <WrapperDefaultScreen id="arquivei-lite">
    <Header />
    <Content>{children}</Content>
    <Footer />
    <HiddenContent />
  </WrapperDefaultScreen>
);

export default DefaultScreen;
