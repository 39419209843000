import getConfig from 'next/config';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

const { publicRuntimeConfig } = getConfig();

const apiKey = publicRuntimeConfig.FIREBASE_API_KEY;
const projectId = publicRuntimeConfig.FIREBASE_PROJECT;
const messagingSenderId = publicRuntimeConfig.FIREBASE_MESSAGING_SENDER_ID;

const config = {
  apiKey,
  authDomain: `${projectId}.firebaseapp.com`,
  databaseURL: `https://${projectId}.firebaseio.com`,
  projectId,
  storageBucket: `${projectId}.appspot.com`,
  messagingSenderId,
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

export const auth = firebase.auth();

export default firebase;
