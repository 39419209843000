import React from 'react';
import { withRouter } from 'next/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button as Btn } from './style';

const track = (tracking, callback, href = '', isNextLink, router, event) => {
  if (typeof tracking === 'function') {
    tracking();
  }

  if (typeof callback === 'function') {
    callback(event);
  }

  if (typeof href === 'string' && href) {
    if (isNextLink) {
      router.push(href);
      // window.location = href
    } else {
      window.open(href, '_blank');
    }
  }
};

const Button = ({
  children,
  className,
  disabled = false,
  href = '',
  isLoading = false,
  isNextLink = false,
  noHover = false,
  onClick,
  router,
  tracking,
  variant = 'primary',
  ...props
}) => (
  <Btn
    className={className}
    disabled={disabled || isLoading}
    isLoading={isLoading}
    onClick={(event) =>
      track(tracking, onClick, href, isNextLink, router, event)
    }
    variant={variant}
    noHover={noHover}
    {...props}
  >
    <span>{children}</span>
    {isLoading && <FontAwesomeIcon icon="circle-notch" size="lg" spin />}
  </Btn>
);

export default withRouter(Button);
export { Button };
