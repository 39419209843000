export const RESET_USER_SESSION = 'user-session/RESET_USER_SESSION';
export const resetUserSession = () => ({
  type: RESET_USER_SESSION,
});

export const SET_SESSION_ID = 'user-session/SET_SESSION_ID';
export const setSessionId = (sessionId) => ({
  sessionId,
  type: SET_SESSION_ID,
});

const ONE_SECOND_IN_MILLISECONDS = 1000;

export const SET_TOKEN_EXPIRES_DATE = 'user-session/SET_TOKEN_EXPIRES_DATE';
export const setTokenExpiresDate = (tokenExpiresDateInSeconds) => ({
  tokenExpiresDate: new Date(
    tokenExpiresDateInSeconds * ONE_SECOND_IN_MILLISECONDS,
  ),
  type: SET_TOKEN_EXPIRES_DATE,
});

export const SET_USER_COOKIES = 'user-session/SET_USER_COOKIES';
export const setUserCookies = (userCookies) => ({
  userCookies,
  type: SET_USER_COOKIES,
});

export const SET_USER_ID = 'user-session/SET_USER_ID';
export const setUserId = (userId) => ({
  userId,
  type: SET_USER_ID,
});

export const SET_USER_IP = 'user-session/SET_USER_IP';
export const setUserIp = (userIp) => ({
  userIp,
  type: SET_USER_IP,
});

export const SIGN_IN = 'user-session/SIGN_IN';
export const signIn = () => ({
  type: SIGN_IN,
});

export const AUTHORIZE = 'user-session/AUTHORIZE';
export const authorize = () => ({
  type: AUTHORIZE,
});
