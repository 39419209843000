import events from '..';

export default function (emailHash, loginMethod, pagePath, pageTitle) {
  return {
    type: events.userSignIn,
    data: {
      emailHash,
      event: events.userSignIn,
      loginMethod,
      pageTitle,
      pagePath,
    },
  };
}
