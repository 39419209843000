import Router from 'next/router';

import firebase, { auth } from 'utils/firebase';
import { generateUserEmailHash } from 'utils/userAuth';

import trackFirebaseError from 'utils/tracking/listeners/firebaseError';
import trackUserSignIn from 'utils/tracking/listeners/userSignIn';
import trackUserSignUp from 'utils/tracking/listeners/userSignUp';
import firebaseErrorEvent from 'utils/tracking/events/firebaseError';
import userSignInEvent from 'utils/tracking/events/userSignIn';
import userSignUpEvent from 'utils/tracking/events/userSignUp';
import { ACCEPT_TOS } from 'utils/endpoints';
import { parseJwt } from 'utils/token';

import api from 'utils/api';

import * as actions from './actions';
import * as selectors from './selectors';
import { actions as notificationCardActions } from 'store/notification-card-deprecated';
import {
  actions as userSessionActions,
  effects as userSessionEffects,
  selectors as userSessionSelectors,
} from 'store/user-session';

const handleForgotPasswordError = (dispatch, { code } = {}) => {
  switch (code) {
    case 'auth/invalid-email':
      dispatch(actions.setUserEmailError('O e-mail informado é inválido'));
      break;
    case 'auth/user-not-found':
      dispatch(
        actions.setUserEmailError(
          'Essa conta não existe ou foi criada através do Google ou Facebook',
        ),
      );
      break;
    default:
      console.error('Unexpected error on ForgotPassword: ', code);
  }
};

export const handleForgotPassword = () => async (dispatch, getState) => {
  const state = getState();
  const userEmail = selectors.getUserEmail(state);

  try {
    await auth.sendPasswordResetEmail(userEmail);
    dispatch(
      notificationCardActions.setNotificationCardContext(
        'email-forgot-my-password',
      ),
    );
    dispatch(notificationCardActions.toggleNotificationCard());
  } catch (error) {
    handleForgotPasswordError(dispatch, error);
  }
};

const checkEmailVerification = (dispatch, payload) => {
  const isEmailVerified = payload && payload.user && payload.user.emailVerified;

  if (!isEmailVerified) {
    dispatch(
      notificationCardActions.setNotificationCardContext(
        'email-needs-confirmation',
      ),
    );
    dispatch(notificationCardActions.toggleNotificationCard());
    dispatch(actions.setUserPassword(''));
  }

  return isEmailVerified;
};

const handlePasswordResetError = (dispatch, { code } = {}) => {
  switch (code) {
    case 'auth/user-not-found':
      dispatch(
        actions.setUserEmailError(
          'Essa conta não existe ou foi criada através do Google ou Facebook',
        ),
      );
      break;
    case 'auth/weak-password':
      dispatch(
        actions.setUserPasswordError(
          'A senha deve conter no mínimo 6 caracteres',
        ),
      );
      break;
    case 'auth/expired-action-code':
    case 'auth/invalid-action-code':
    case 'auth/user-disabled':
    default:
      console.error('Unexpected error on PasswordReset: ', code);
  }
};

const handleSignInWithEmailAndPasswordError = (dispatch, { code } = {}) => {
  switch (code) {
    case 'auth/invalid-email':
      dispatch(actions.setUserEmailError('O e-mail informado é inválido'));
      break;
    case 'auth/user-not-found':
      dispatch(
        actions.setUserEmailError(
          'Essa conta não existe ou foi criada através do Google ou Facebook',
        ),
      );
      break;
    case 'auth/wrong-password':
      dispatch(
        actions.setUserPasswordError(
          'Senha incorreta. Tente novamente ou clique em "Esqueceu sua senha?"',
        ),
      );
      break;
    case 'auth/user-disabled':
    default:
      console.error('Unexpected error on SignInWithEmailAndPassword: ', code);
  }
};

const handleSignInWithEmailAndPassword = async (dispatch, state) => {
  const isPasswordReset = selectors.getIsPasswordReset(state);
  const userEmail = selectors.getUserEmail(state);
  const userPassword = selectors.getUserPassword(state);

  dispatch(actions.setUserEmailError(''));
  dispatch(actions.setUserPasswordError(''));

  if (isPasswordReset) {
    const oobCode = selectors.getOobCode(state);
    try {
      await auth.confirmPasswordReset(oobCode, userPassword);
    } catch (error) {
      handlePasswordResetError(dispatch, error);
      return null;
    }
  }

  try {
    const payload = await auth.signInWithEmailAndPassword(
      userEmail,
      userPassword,
    );
    const isEmailVerified = checkEmailVerification(dispatch, payload);

    return isEmailVerified ? payload : null;
  } catch (error) {
    handleSignInWithEmailAndPasswordError(dispatch, error);
    return null;
  }
};

const handleSignInWithPopupError = ({ code } = {}) => {
  switch (code) {
    case 'auth/account-exists-with-different-credential':
    case 'auth/auth-domain-config-required':
    case 'auth/cancelled-popup-request':
    case 'auth/operation-not-allowed':
    case 'auth/operation-not-supported-in-this-environment':
    case 'auth/popup-blocked':
    case 'auth/popup-closed-by-user':
    case 'auth/unauthorized-domain':
    default:
      trackFirebaseError(firebaseErrorEvent(code));
      console.error('Unexpected error on SignInWithPopup: ', code);
  }
};

const handleSignInWithPopup = async (provider) => {
  const authProvider =
    provider === 'google'
      ? new firebase.auth.GoogleAuthProvider()
      : new firebase.auth.FacebookAuthProvider();

  try {
    const payload = await auth.signInWithPopup(authProvider);
    return payload;
  } catch (error) {
    handleSignInWithPopupError(error);
    return null;
  }
};

export const handleSignIn = (provider = 'email') => async (
  dispatch,
  getState,
) => {
  const state = getState();

  const payload =
    provider === 'email'
      ? await handleSignInWithEmailAndPassword(dispatch, state)
      : await handleSignInWithPopup(provider);

  if (payload === null) {
    return;
  }

  const { user } = payload;

  const token = await user.getIdToken();

  try {
    await fetch('/login', {
      body: JSON.stringify({ token }),
      credentials: 'same-origin',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      method: 'POST',
    });

    const { uid } = user;
    await auth.updateCurrentUser(user);

    const { exp } = parseJwt(token);

    dispatch(userSessionActions.setUserId(uid));
    dispatch(userSessionActions.setSessionId(token));

    if (exp) {
      dispatch(userSessionActions.setTokenExpiresDate(exp));
    }

    dispatch(userSessionActions.signIn());

    trackUserSignIn(
      userSignInEvent(generateUserEmailHash(), 'email', 'Login', '/sign/in'),
    );

    Router.push('/batch');
  } catch (error) {
    trackFirebaseError(firebaseErrorEvent('server-error'));
    console.error('Unexpected error on NodeServer: ', error);
  }
};

export const handleAcceptTOS = () => async (dispatch, getState) => {
  const state = getState();

  await dispatch(userSessionEffects.trackUserInfo());

  try {
    await api.post(ACCEPT_TOS, {
      ip: userSessionSelectors.getUserIp(state),
      token: userSessionSelectors.getSessionId(state),
    });

    dispatch(userSessionActions.authorize());
    dispatch(actions.resetUserAuthentication());

    return Promise.resolve();
  } catch (error) {
    trackFirebaseError(firebaseErrorEvent('server-error'));
    console.error('Unexpected error on NodeServer: ', error);

    return Promise.reject();
  }
};

export const handleSignOut = () => async (dispatch) => {
  const requestHeader = {
    credentials: 'same-origin',
    method: 'GET',
  };

  try {
    await auth.signOut();
    await fetch('/logout', requestHeader);
  } catch (error) {
    console.error('Unexpected error on SignOut: ', error);
  } finally {
    dispatch(userSessionActions.resetUserSession());
  }
};

const handleSignUpError = (dispatch, { code } = {}) => {
  switch (code) {
    case 'auth/email-already-in-use':
      dispatch(
        actions.setUserEmailError('O e-mail informado já foi utilizado'),
      );
      break;
    case 'auth/invalid-email':
      dispatch(actions.setUserEmailError('O e-mail informado é inválido'));
      break;
    case 'auth/weak-password':
      dispatch(
        actions.setUserPasswordError(
          'A senha deve conter no mínimo 6 caracteres',
        ),
      );
      break;
    case 'auth/operation-not-allowed':
    default:
      console.error('Unexpected error on SignUp: ', code);
  }
};

export const handleSignUp = () => async (dispatch, getState) => {
  const state = getState();
  const userEmail = selectors.getUserEmail(state).replace(/(\+.*@)/g, '@');
  const userPassword = selectors.getUserPassword(state);

  dispatch(actions.setUserEmailError(''));
  dispatch(actions.setUserPasswordError(''));

  try {
    const payload = await auth.createUserWithEmailAndPassword(
      userEmail,
      userPassword,
    );
    await payload.user.sendEmailVerification();
  } catch (error) {
    handleSignUpError(dispatch, error);
    return;
  }

  dispatch(actions.resetUserAuthentication());
  trackUserSignUp(
    userSignUpEvent(
      generateUserEmailHash(userEmail),
      'email',
      'Cadastro',
      '/sign/up',
    ),
  );
  Router.push('/email-confirmation');
};

export const validateAuthOperation = (query = {}) => async (dispatch) => {
  const { mode, oobCode } = query;

  let actionCodeInfo = null;
  if (oobCode) {
    try {
      actionCodeInfo = await auth.checkActionCode(oobCode);
    } catch (error) {
      console.error(error);
      return;
    }
  }

  if (actionCodeInfo !== null) {
    const {
      operation,
      data: { email },
    } = actionCodeInfo;

    if (mode === 'resetPassword' && operation === 'PASSWORD_RESET') {
      dispatch(actions.setOobCode(oobCode));
      dispatch(actions.setUserEmail(email));
      dispatch(actions.togglePasswordReset());
    } else if (mode === 'verifyEmail' && operation === 'VERIFY_EMAIL') {
      try {
        await auth.applyActionCode(query.oobCode);
      } catch (error) {
        console.error(error);
        return;
      }
      dispatch(actions.setUserEmail(email));
      dispatch(actions.toggleEmailVerification());
    }
  }
};

export const validateUserEmail = () => (dispatch, getState) => {
  const state = getState();
  const userEmail = selectors.getUserEmail(state);

  const isValidEmail = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    userEmail,
  );
  if (isValidEmail) {
    dispatch(actions.setUserEmailError(''));
  } else {
    dispatch(actions.setUserEmailError('O e-mail informado é inválido'));
  }
};

export const handleRefreshToken = () => async (dispatch) => {
  if (!auth.currentUser) {
    throw new Error('handleRefreshToken: User not authenticated');
  }

  const token = await auth.currentUser.getIdToken(true);

  const { exp } = parseJwt(token);

  dispatch(userSessionActions.setSessionId(token));
  return !!exp && dispatch(userSessionActions.setTokenExpiresDate(exp));
};
