import styled, { css } from 'styled-components';
import { switchProp, theme } from 'styled-tools';

export default styled.a`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  background-color: inherit;
  border: none;
  cursor: pointer;
  ${switchProp(
    'variant',
    {
      success: css`
        color: ${theme('colors.success')};
      `,
      error: css`
        color: ${theme('colors.error')};
      `,
      alert: css`
        color: ${theme('colors.alert')};
      `,
      dark: css`
        color: ${theme('colors.dark')};
      `,
      primary: css`
        color: ${theme('colors.primary')};
      `,
      white: css`
        color: ${theme('colors.white')};
      `,
      extraDark: css`
        color: ${theme('colors.extraDark')};
      `,
    },
    css`
      color: ${theme('colors.extraDark')};
    `,
  )}
  font-family: 'Rubik', sans-serif;
  font-size: ${theme('text.cta.fontSize')};
  font-weight: bold;
  outline: none;
  padding: 0;
  text-decoration: none;
`;
