import React from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import { withTracking } from 'react-tracker';
import { connect } from 'react-redux';
import { Row, Col } from 'styled-bootstrap-grid';

import Radio from 'components/Radio';
import { Button } from 'components/Button';
import { Input } from 'components/Input';
import { Heading } from 'components/_styled';

import Content from './_style';

import pageViewEvent from 'utils/tracking/events/pageView';

import {
  effects as batchEffects,
  selectors as batchSelectors,
} from 'store/batch';

import coreConfig from 'config/core.json';

import Receipt from './Receipt';

const PAGSEGURO_SIGNUP = 'https://cadastro.pagseguro.uol.com.br/';

const InnerContent = styled.div`
  padding-top: 32px;
  text-align: initial;
  width: 100%;

  > div div {
    padding: 0;

    &#checkout-inputs {
      min-height: 450px;

      @media (max-width: 768px) {
        text-align: center;
      }
    }

    &.checkout-section {
      margin-bottom: 32px;
    }

    > h2 {
      text-align: left;
    }

    &.center-start {
      display: flex;
      justify-content: center;
      align-items: start;
    }

    .invoice {
      margin: 8px 0;
    }

    .alert-card {
      margin-top: 16px;
    }
  }

  .gray {
    color: ${theme('colors.gray400')};
  }

  input {
    max-width: 252px;
    box-sizing: border-box;

    font-size: 18px;
    line-height: 1.5;
    letter-spacing: 0.5px;
    background-color: #f3f3f3;
    padding: 0 32px;
    color: #001634;

    ::placeholder {
      color: #a7a7a7;
    }
  }

  button {
    margin: 16px 4px;
  }
`;

const ErrorMessage = styled.div`
  color: ${theme('colors.error')};
  font-size: 14px;
  font-weight: normal;
  margin-top: 8px;
`;

const StyledSubtitle = styled(Heading.Subtitle)`
  margin-bottom: 8px;
`;

class PurchaseCredits extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputError: false,
      inputValue: '',
      nominalValue: 0,
      pagseguroAccountRadio: '',
    };

    this.minimumPurchaseQuantity = 200;
  }

  componentDidMount() {
    const { trackPageView } = this.props;
    trackPageView('/purchase', 'Comprar créditos');
  }

  onChange = (e) => {
    let inputValue = e.target.value.replace(/\D/g, '');
    let nominalValue = Number(inputValue);

    if (nominalValue > 1000000) {
      // arbitrary rule to prevent an input overflow
      nominalValue = 1000000;
      inputValue = 1000000;
    }

    this.setState({
      inputError: false,
      inputValue,
      nominalValue,
    });
  };

  onSelectInvoice = (e) =>
    this.setState({
      pagseguroAccountRadio: e.target.value,
    });

  purchase = () => {
    const { nominalValue, pagseguroAccountRadio } = this.state;
    const { onPurchase } = this.props;

    this.setState({ inputError: false });

    if (nominalValue < this.minimumPurchaseQuantity) {
      setTimeout(() => this.setState({ inputError: true }), 100);
      return;
    }

    if (pagseguroAccountRadio === 'yes') {
      onPurchase({
        id: 'keys-nominal',
        description: 'Credito em chaves',
        quantity: nominalValue,
      });
    } else {
      this.setState({ pagseguroAccountRadio: '' });
      window.open(PAGSEGURO_SIGNUP, '_blank');
    }
  };

  render() {
    const { isLoading, onClose } = this.props;
    const {
      inputError,
      inputValue,
      nominalValue,
      pagseguroAccountRadio,
    } = this.state;

    return (
      <Content fullWidth style={{ maxHeight: '623px' }}>
        <Row>
          <Col sm={12} md={8} mdOffset={2}>
            <div>
              <Heading.Title bold>
                Adquira mais chaves para continuar consultando
              </Heading.Title>
            </div>
            <InnerContent>
              <Row>
                <Col sm={12} md={6} id="checkout-inputs">
                  <div className="checkout-section">
                    <StyledSubtitle bold small>
                      1. Escolha a quantidade de chaves abaixo
                    </StyledSubtitle>
                    <Input
                      error={inputError}
                      onChange={this.onChange}
                      value={inputValue}
                      type="text"
                      placeholder="MÍNIMO 200 CHAVES"
                    />
                    {inputError && nominalValue < 200 && (
                      <ErrorMessage>
                        {'A quantidade mínima de compra é de '}
                        {this.minimumPurchaseQuantity}
                        {' chaves'}
                      </ErrorMessage>
                    )}
                  </div>
                  <div className="checkout-section">
                    <StyledSubtitle bold small>
                      2. Você possui conta no Pagseguro?
                    </StyledSubtitle>
                    <div className="invoice">
                      <Radio
                        checked={pagseguroAccountRadio === 'yes'}
                        onChange={this.onSelectInvoice}
                        label="Sim"
                        id="pagseguro-account-yes"
                        name="pagseguro-account"
                        value="yes"
                      />
                    </div>
                    <div className="invoice">
                      <Radio
                        checked={pagseguroAccountRadio === 'no'}
                        onChange={this.onSelectInvoice}
                        label="Não"
                        id="pagseguro-account-no"
                        name="pagseguro-account"
                        value="no"
                      />
                    </div>
                  </div>
                </Col>

                <Col className="center-start" sm={12} md={6}>
                  <Receipt
                    promotionPrice={coreConfig.accessKeyUnitPrice}
                    promotionValue={nominalValue}
                  />
                </Col>
              </Row>
            </InnerContent>
            <Button onClick={onClose}>VOLTAR</Button>
            <Button
              onClick={this.purchase}
              isLoading={isLoading}
              disabled={!inputValue || !pagseguroAccountRadio}
              variant="success"
            >
              COMPRAR
            </Button>
          </Col>
        </Row>
      </Content>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: batchSelectors.getPurchaseLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  onPurchase: (itemNominal) => {
    dispatch(batchEffects.purchaseCredits(itemNominal));
  },
});

const mapTrackingToProps = (trackEvent) => ({
  trackPageView: (pagePath, pageTitle) =>
    trackEvent(pageViewEvent(pagePath, pageTitle)),
});

export default withTracking(mapTrackingToProps)(
  connect(mapStateToProps, mapDispatchToProps)(PurchaseCredits),
);
