import styled from 'styled-components';
import { theme, ifProp } from 'styled-tools';

export default styled.h2`
  font-size: 32px;
  font-weight: ${ifProp('bold', 'bold', 'normal')};
  text-align: center;
  color: ${theme('colors.dark')};

  @media (max-width: 768px) {
    font-size: 22px;
  }
`;
