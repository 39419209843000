import React from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import { withRouter } from 'next/router';
import { Row, Col } from 'styled-bootstrap-grid';
import { withTracking } from 'react-tracker';
import externalArquiveiEvent from 'utils/tracking/events/externalArquivei';
import PlainButton from 'components/Button/PlainButton';

import Terms from './Terms';

const EXTERNAL_URL_MORE_THAN_LITE = 'https://arquivei.com.br/lite/maisquelite/';

const Bottom = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;

  margin-top: calc(92px + 60px);
`;

const Foot = styled.div`
  height: 60px;
  background-color: ${theme('colors.dark')};
  padding: 0 32px;

  > div {
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    color: white;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const ArquiveiLogo = styled.img`
  padding-top: 7px;
`;

const Footer = ({
  hideBigFooter = false,
  router,
  trackExternalArquiveiClick,
}) => {
  const getCurrentPathBeforeTracking = (trackingCallback, destination) => {
    const routes = {
      '/': 'Home',
      '/batch': 'Consulta em lote',
    };

    trackingCallback(router.route, routes[router.route], destination);
  };

  return (
    <Bottom id="arquivei-footer">
      <Foot hide={hideBigFooter}>
        <Row className="solutions">
          <Col sm={12} md={3}>
            <PlainButton
              href={EXTERNAL_URL_MORE_THAN_LITE}
              tracking={() =>
                getCurrentPathBeforeTracking(
                  trackExternalArquiveiClick,
                  EXTERNAL_URL_MORE_THAN_LITE,
                )
              }
            >
              <ArquiveiLogo
                src="/static/logo-qive-medium.svg"
                height="30"
                alt="https://www.arquivei.com.br"
              />
            </PlainButton>
          </Col>
          <Col sm={12} md={6}>
            {'Possui Certificado Digital? Temos a '}
            {
              <PlainButton
                href={EXTERNAL_URL_MORE_THAN_LITE}
                style={{ fontSize: 'inherit', textDecoration: 'underline' }}
                tracking={() =>
                  getCurrentPathBeforeTracking(
                    trackExternalArquiveiClick,
                    EXTERNAL_URL_MORE_THAN_LITE,
                  )
                }
                variant="alert"
              >
                solução ideal para você!
              </PlainButton>
            }
            <br />A consulta com Certificados é mais segura, mais prática e
            livre de erros.
          </Col>
        </Row>
      </Foot>
      <Terms />
    </Bottom>
  );
};

const mapTrackingToProps = (trackEvent) => ({
  trackExternalArquiveiClick: (pagePath, pageTitle, destinationPage) => {
    trackEvent(externalArquiveiEvent(pagePath, pageTitle, destinationPage));
  },
});

export { Terms };
export default withTracking(mapTrackingToProps)(withRouter(Footer));
export { Footer };
