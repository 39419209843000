import { Map } from 'immutable';

import * as actions from './actions';
import * as effects from './effects';
import * as selectors from './selectors';

const scaffold = Map({
  isEmailVerification: false,
  isPasswordReset: false,
  oobCode: '',
  userEmail: '',
  userEmailError: '',
  userPassword: '',
  userPasswordError: '',
});

export default (state = scaffold, action = {}) => {
  switch (action.type) {
    case actions.RESET_USER_AUTHENTICATION:
      return scaffold;
    case actions.SET_OOB_CODE:
      return state.set('oobCode', action.oobCode);
    case actions.SET_USER_EMAIL:
      return state.set('userEmail', action.userEmail);
    case actions.SET_USER_EMAIL_ERROR:
      return state.set('userEmailError', action.userEmailError);
    case actions.SET_USER_PASSWORD:
      return state.set('userPassword', action.userPassword);
    case actions.SET_USER_PASSWORD_ERROR:
      return state.set('userPasswordError', action.userPasswordError);
    case actions.TOGGLE_EMAIL_VERIFICATION:
      return state.update('isEmailVerification', (value) => !value);
    case actions.TOGGLE_PASSWORD_RESET:
      return state.update('isPasswordReset', (value) => !value);
    default:
      return state;
  }
};

export { actions, effects, selectors };
