/* eslint-disable no-confusing-arrow */
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { ifProp, theme } from 'styled-tools';

import * as Contexts from './contexts';

import { actions, selectors } from 'store/notification-card-deprecated';
import { effects as singleKeyQueryEffects } from 'store/single-key-query';

const Card = styled.div`
  background-color: ${theme('colors.white')};
  color: ${theme('colors.dark')};
  font-size: 14px;
  font-weight: bold;
  width: 100%;
  min-height: ${({ full }) => (full ? '100vh' : '345px')};
  padding: 48px 0;
  box-shadow: 0px 0px 5px grey;
  text-align: center;

  position: fixed;
  max-height: 823px;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 9999;

  animation: slide-in ${ifProp('full', '1.5s', '1s')} ease-in-out;

  @keyframes slide-in {
    from {
      bottom: ${ifProp('full', '-120%', '-50%')};
    }
    to {
      bottom: 0;
    }
  }
`;

const Backdrop = styled.div`
  position: fixed;
  z-index: 9990;

  top: 0;
  width: 100%;
  height: 100%;

  background-color: ${theme('colors.black')};
  opacity: 0.6;
`;

const renderContext = (context, onClose, promiseHandler) => {
  switch (context) {
    case 'account-multiple-credentials':
      return <Contexts.AccountMultipleCredentials onClose={onClose} />;
    case 'account-reset-password':
      return <Contexts.AccountResetPassword onClose={onClose} />;
    case 'account-session-expired':
      return <Contexts.AccountSessionExpired onClose={onClose} />;
    case 'batch-accept-bad-format':
      return (
        <Contexts.BatchAcceptBadFormat
          onClose={onClose}
          promiseHandler={promiseHandler}
        />
      );
    case 'batch-accept-both':
      return (
        <Contexts.BatchAcceptBoth
          onClose={onClose}
          promiseHandler={promiseHandler}
        />
      );
    case 'batch-accept-duplicates':
      return (
        <Contexts.BatchAcceptDuplicates
          onClose={onClose}
          promiseHandler={promiseHandler}
        />
      );
    case 'batch-bad-format':
      return <Contexts.BatchBadFormat onClose={onClose} />;
    case 'batch-confirm':
      return (
        <Contexts.BatchConfirm
          onClose={onClose}
          promiseHandler={promiseHandler}
        />
      );
    case 'batch-limit-exceeded':
      return <Contexts.BatchLimitExceeded onClose={onClose} />;
    case 'batch-success':
      return <Contexts.BatchSuccess onClose={onClose} />;
    case 'batch-too-many-lines':
      return <Contexts.BatchTooManyLines onClose={onClose} />;
    case 'email-confirmed':
      return <Contexts.EmailConfirmed onClose={onClose} />;
    case 'email-forgot-my-password':
      return <Contexts.EmailForgotMyPassword onClose={onClose} />;
    case 'email-needs-confirmation':
      return <Contexts.EmailNeedsConfirmation onClose={onClose} />;
    case 'home-bad-format':
      return <Contexts.HomeBadFormat onClose={onClose} />;
    case 'home-invalid':
      return <Contexts.HomeInvalidAccessKey onClose={onClose} />;
    case 'home-is-cte':
      return <Contexts.HomeIsCte onClose={onClose} />;
    case 'home-less-than-forty-four':
      return <Contexts.HomeLessThanFortyFour onClose={onClose} />;
    case 'home-not-found':
      return <Contexts.HomeNotFound onClose={onClose} />;
    case 'home-success':
      return <Contexts.HomeSuccess onClose={onClose} />;
    case 'purchase-credits':
      return <Contexts.PurchaseCredits onClose={onClose} />;
    default:
      return <Contexts.TryAgain onClose={onClose} />;
  }
};

class NotificationCard extends React.Component {
  componentDidMount() {
    const { onClose, promiseHandler } = this.props;

    document.onkeydown = (event) => {
      const e = event || window.event;

      if (e.keyCode === 27) {
        if (promiseHandler.get('reject') === 'function') {
          promiseHandler.get('reject')();
        }

        onClose();
      }
    };
  }

  getSizeByContext = (context) => ['purchase-credits'].includes(context);

  handleClose = () => {
    const { context, initializeGoogleRecaptcha, onClose } = this.props;

    if (context.startsWith('home')) {
      initializeGoogleRecaptcha();
    }

    onClose();
  };

  render() {
    const {
      className,
      context,
      isOpen = false,
      onClose,
      promiseHandler,
    } = this.props;

    return (
      isOpen && (
        <React.Fragment>
          <Backdrop onClick={this.handleClose} />
          <Card className={className} full={this.getSizeByContext(context)}>
            {renderContext(context, onClose, promiseHandler)}
          </Card>
        </React.Fragment>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  context: selectors.getNotificationCardContext(state),
  isOpen: selectors.getNotificationCardIsOpen(state),
  promiseHandler: selectors.getPromiseHandler(state),
});

const mapDispatchToProps = (dispatch) => ({
  initializeGoogleRecaptcha: () =>
    dispatch(singleKeyQueryEffects.initializeGoogleRecaptcha()),
  onClose: () => dispatch(actions.toggleNotificationCard(false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationCard);
