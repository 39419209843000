import * as Sentry from '@sentry/browser';

const isTokenExpired = (tokenExpiresDate) => {
  const tokenExpiresInMilliseconds = Date.parse(tokenExpiresDate);

  if (Number.isNaN(tokenExpiresInMilliseconds)) {
    return false;
  }

  const today = Date.now();

  return tokenExpiresInMilliseconds < today;
};

const parseJwt = (token) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join(''),
    );

    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error(error);

    Sentry.captureException(error, {
      tags: {
        method: 'parseJwt',
      },
      extras: {
        token,
      },
    });
  }

  return {};
};

export { isTokenExpired, parseJwt };
