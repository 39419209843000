import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp, theme, prop } from 'styled-tools';
import commonStyle from './style/common';

const Card = styled.div`
  box-shadow: 0px 0px 0px 2px ${theme('colors.gray300')} inset;

  width: ${prop('width', '151px')};
  height: ${prop('width', '79px')};

  .title {
    color: ${theme('colors.primary')};
    font-size: ${theme('text.cta.fontSize')};
    font-weight: bold;
  }

  .content {
    color: ${theme('colors.dark')};
    font-size: ${theme('text.title.fontSize')};
    font-weight: bold;

    &.small {
      font-size: ${theme('text.body.fontSize')};
    }
  }

  ${commonStyle}

  ${ifProp(
    'fixed',
    css`
      @media (min-width: 1280px) {
        box-shadow: 2px 0px 0px 0px ${theme('colors.gray300')} inset,
          0px 2px 0px 0px ${theme('colors.gray300')} inset,
          0px -2px 0px 0px ${theme('colors.gray300')} inset;
      }
    `,
  )}
`;

export default ({ className, action, title = '', content = '', ...props }) => (
  <Card className={className} {...props}>
    <div className="title">{title}</div>
    <div className="content small">{content}</div>
  </Card>
);
