import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';

import { withRouter } from 'next/router';
import { withTracking } from 'react-tracker';
import { trackClick } from 'utils/tracking';
import routeToBatchEvent from 'utils/tracking/events/routeToBatch';

import NavbarItem from '../../NavbarItem';

const StyledAnchor = styled.a`
  text-decoration: none;
`;

const AnonymousUserAreaItem = ({ href, ...props }) => (
  <Link href={href}>
    <StyledAnchor>
      <NavbarItem {...props} />
    </StyledAnchor>
  </Link>
);

const AnonymousUserArea = ({ router, trackRouteToBatch }) => (
  <React.Fragment>
    <AnonymousUserAreaItem href="/sign/up" title="CRIAR CONTA" type="user" />
    <AnonymousUserAreaItem href="/sign/in" title="ENTRAR" type="user" />
    <AnonymousUserAreaItem
      href="/sign/up"
      title="BAIXAR NOTAS EM LOTE"
      tracking={() => trackClick(trackRouteToBatch, router.route)}
      type="user-highlight"
    />
  </React.Fragment>
);

const mapTrackingToProps = (trackEvent) => ({
  trackRouteToBatch: (pagePath, pageTitle) => {
    trackEvent(routeToBatchEvent(pagePath, pageTitle));
  },
});

export default withTracking(mapTrackingToProps)(withRouter(AnonymousUserArea));
