import React from 'react';
import { connect } from 'react-redux';

import { withRouter } from 'next/router';
import { withTracking } from 'react-tracker';
import { trackClick } from 'utils/tracking';
import externalArquiveiEvent from 'utils/tracking/events/externalArquivei';

import { effects as userAuthenticationEffects } from 'store/user-authentication';
import { Divider } from 'layouts/components/atoms';
import NavbarItem from '../../NavbarItem';

const EXTERNAL_URL_CONTACT = 'https://arquivei.com.br/lite/contato/';

const LoggedUserArea = ({ onLogout, router, trackExternalArquiveiClick }) => (
  <React.Fragment>
    <NavbarItem
      href={EXTERNAL_URL_CONTACT}
      title="CONTATO"
      tracking={() =>
        trackClick(
          trackExternalArquiveiClick,
          router.route,
          EXTERNAL_URL_CONTACT,
        )
      }
      type="user"
    />
    <Divider />
    <NavbarItem
      onClick={() => onLogout(router)}
      title="SAIR"
      type="user-logout"
    />
  </React.Fragment>
);

const mapDispatchToProps = (dispatch) => ({
  onLogout: async (router) => {
    await dispatch(userAuthenticationEffects.handleSignOut());
    router.push('/');
  },
});

const mapTrackingToProps = (trackEvent) => ({
  trackExternalArquiveiClick: (pagePath, pageTitle, destinationPage) => {
    trackEvent(externalArquiveiEvent(pagePath, pageTitle, destinationPage));
  },
});

export default withTracking(mapTrackingToProps)(
  connect(null, mapDispatchToProps)(withRouter(LoggedUserArea)),
);
