import React from 'react';
import { Button } from '../../Button';
import Content from './_style';

import { Heading } from '../../_styled';

export default ({ onClose }) => (
  <Content>
    <div>
      <Heading.Title bold>
        Tivemos um problema ao processar sua solicitação
      </Heading.Title>
      <Heading.Title bold>Tente novamente</Heading.Title>
    </div>
    <Button onClick={onClose}>TENTAR NOVAMENTE</Button>
  </Content>
);
