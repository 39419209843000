import { Map, List } from 'immutable';
import * as actions from './actions';
import * as effects from './effects';
import * as selectors from './selectors';

const scaffold = Map({
  badFormatLines: null,
  batch_size: null,
  currentTab: 'batch-generator',
  duplicatedLines: null,
  isBatchLoading: false,
  isFetchingReport: false,
  isFetchingCredits: false,
  isFetchingPurchases: false,
  isPurchaseLoading: false,
  purchaseData: null,
  remainingBatch: 0,
  reportData: null,
  userCredits: null,
  zipRequestIds: List(),
});

export default (state = scaffold, action) => {
  switch (action.type) {
    case actions.CHANGE_CURRENT_TAB:
      return state.set('currentTab', action.tab);
    case actions.SET_BAD_FORMAT_LINES:
      return state.set('badFormatLines', action.value);
    case actions.SET_DUPLICATED_LINES:
      return state.set('duplicatedLines', action.value);
    case actions.SET_BATCH_SIZE:
      return state.set('batchSize', action.value);
    case actions.SET_REMAINING_BATCH:
      return state.set('remainingBatch', action.value);
    case actions.SET_REPORT_DATA:
      return state.set('reportData', action.data);
    case actions.SET_PURCHASE_DATA:
      return state.set('purchaseData', action.data);
    case actions.SET_CREDITS:
      return state.set('userCredits', action.userCredits);
    case actions.PUSH_ZIP_REQUEST_ID:
      return state.set(
        'zipRequestIds',
        state.get('zipRequestIds').push(action.id),
      );
    case actions.DELETE_ZIP_REQUEST_ID:
      return state.set(
        'zipRequestIds',
        state
          .get('zipRequestIds')
          .delete(state.get('zipRequestIds').indexOf(action.id)),
      );
    case actions.TOGGLE_BATCH_LOADING:
      return state.update('isBatchLoading', (isLoading) => !isLoading);
    case actions.TOGGLE_PURCHASE_LOADING:
      if (typeof action.value === 'boolean') {
        return state.set('isPurchaseLoading', action.value);
      }
      return state.update('isPurchaseLoading', (isLoading) => !isLoading);
    case actions.TOGGLE_FETCH_REPORT:
      return state.update('isFetchingReport', (isFetching) => !isFetching);
    case actions.TOGGLE_FETCH_PURCHASES:
      return state.update('isFetchingPurchases', (isFetching) => !isFetching);
    case actions.TOGGLE_FETCH_CREDITS:
      if (typeof action.value === 'boolean') {
        return state.set('isFetchingCredits', action.value);
      }
      return state.update('isFetchingCredits', (isFetching) => !isFetching);
    default:
      return state;
  }
};

export { actions, effects, selectors };
