import React from 'react';
import styled, { css } from 'styled-components';
import { theme, ifProp } from 'styled-tools';

import { PlainButton } from 'components';

const StyledNavbarItem = styled(PlainButton)`
  color: ${theme('colors.primary')};

  ${ifProp(
    { type: 'external' },
    css`
      flex-direction: column;
      align-items: flex-end;
      color: ${theme('colors.extraDark')};
    `,
  )}

  ${ifProp(
    { type: 'user-logout' },
    css`
      color: ${theme('colors.gray400')};
    `,
  )}

  ${ifProp(
    { type: 'user-highlight' },
    css`
      padding: 16px;
      border-radius: 24px;
      border: 2px solid ${theme('colors.primary')};
    `,
  )}
`;

const NavbarItem = ({ title, type, ...props }) => {
  const children =
    type === 'external'
      ? title.split('\\n').map((item) => <span key={item}>{item}</span>)
      : title;

  return (
    <StyledNavbarItem type={type} {...props}>
      {children}
    </StyledNavbarItem>
  );
};

export default NavbarItem;
