export const SET_NOTIFICATION_CARD_CONTEXT =
  'notification-card-deprecated/SET_NOTIFICATION_CARD_CONTEXT';
export const setNotificationCardContext = (context) => ({
  context,
  type: SET_NOTIFICATION_CARD_CONTEXT,
});

export const SET_NOTIFICATION_PROMISE_RESOLVE =
  'notification-card-deprecated/SET_NOTIFICATION_PROMISE_RESOLVE';
export const setNotificationPromiseResolve = (resolve, reject) => ({
  resolve,
  reject,
  type: SET_NOTIFICATION_PROMISE_RESOLVE,
});

export const TOGGLE_NOTIFICATION_CARD =
  'notification-card-deprecated/TOGGLE_NOTIFICATION_CARD';
export const toggleNotificationCard = (value) => ({
  type: TOGGLE_NOTIFICATION_CARD,
  value,
});
