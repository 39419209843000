import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'next/router';
import { Button } from '../../Button';
import Content from './_style';

import { Heading } from '../../_styled';

import { effects as userAuthenticationEffects } from 'store/user-authentication';

const AccountSessionExpired = ({ onClose, onLogout, router }) => {
  const handleLogout = () => {
    onLogout(router);
    onClose();
  };

  return (
    <Content>
      <div>
        <Heading.Title>Sua sessão expirou</Heading.Title>
        <Heading.Title bold>Faça o login novamente</Heading.Title>
      </div>
      <Button onClick={handleLogout}>FAZER LOGIN</Button>
    </Content>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onLogout: async (router) => {
    await dispatch(userAuthenticationEffects.handleSignOut());
    router.push('/sign/in');
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(withRouter(AccountSessionExpired));
