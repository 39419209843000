const getState = (state) => state.userAuthentication;

export const getIsEmailVerification = (state) =>
  getState(state).get('isEmailVerification');
export const getIsPasswordReset = (state) =>
  getState(state).get('isPasswordReset');
export const getOobCode = (state) => getState(state).get('oobCode');
export const getUserEmail = (state) => getState(state).get('userEmail');
export const getUserEmailError = (state) =>
  getState(state).get('userEmailError');
export const getUserPassword = (state) => getState(state).get('userPassword');
export const getUserPasswordError = (state) =>
  getState(state).get('userPasswordError');
