import React from 'react';
import { Button } from '../../Button';
import Content from './_style';

import { Heading } from '../../_styled';

export default ({ onClose }) => (
  <Content>
    <div>
      <Heading.Title>
        Ops... Esse e-mail está associado a outra conta
      </Heading.Title>
      <Heading.Title bold>
        Utilize a conta correta ou crie uma nova :)
      </Heading.Title>
    </div>
    <Button onClick={onClose}>ENTENDI</Button>
  </Content>
);
