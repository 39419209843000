import React from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Icon = styled(FontAwesomeIcon)`
  color: ${theme('colors.alert')};
  flex-grow: 1;
  margin-right: 12px;
`;

const Card = styled.div`
  border: 1px solid ${theme('colors.gray200')};
  border-left: 5px solid ${theme('colors.alert')};
  border-radius: 4px;
  color: ${theme('colors.dark')};
  font-weight: normal;

  display: flex;
  padding: 21px !important;

  > .alert-card-label {
    flex-grow: 11;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default ({
  className = '',
  label = '',
  size = '3x',
  variant = 'alert',
  ...props
}) => (
  <Card className={className}>
    <Icon icon="info-circle" size={size} variant={variant} {...props} />
    <span className="alert-card-label">{label}</span>
  </Card>
);
