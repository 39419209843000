import styled from 'styled-components';
import { ifProp, theme } from 'styled-tools';

export default styled.span`
  width: 1px;
  height: 35px;
  background-color: ${theme('colors.gray200')};

  @media screen and (max-width: 768px) {
    display: ${ifProp('hideMd', 'none')};
  }
`;
