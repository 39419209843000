export default {
  primary: '#4076FA',
  primaryHover: '#1f3A7C',
  success: '#00D233',
  successHover: '#006819',
  error: '#F15025',
  alert: '#E8C547',
  dark: '#001634',
  extraDark: '#091223',
  gray100: '#F3F3F3',
  gray200: '#D8D8D8',
  gray300: '#BFBFBF',
  gray400: '#A7A7A7',
  black: '#000000',
  white: '#FFFFFF',
};
