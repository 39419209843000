const getState = (state) => state.userSession;

export const getSignedIn = (state) => getState(state).get('signedIn');
export const getAuthorized = (state) => getState(state).get('authorized');
export const getSessionId = (state) => getState(state).get('sessionId');
export const getTokenExpiresDate = (state) =>
  getState(state).get('tokenExpiresDate');
export const getUserCookies = (state) => getState(state).get('userCookies');
export const getUserId = (state) => getState(state).get('userId');
export const getUserIp = (state) => getState(state).get('userIp');
