import events from '..';
import track from '../gtm';

function trackFirebaseError(event) {
  track(event.data);
  return event;
}
trackFirebaseError.eventType = events.firebaseError;

export default trackFirebaseError;
