import { Map } from 'immutable';

import * as actions from './actions';
import * as effects from './effects';
import * as selectors from './selectors';

const scaffold = Map({
  signedIn: false,
  authorized: false,
  sessionId: '',
  userCookies: '',
  userId: '',
  userIp: '',
  tokenExpiresDate: null,
});

export default (state = scaffold, action = {}) => {
  switch (action.type) {
    case actions.RESET_USER_SESSION:
      return scaffold;
    case actions.SET_SESSION_ID:
      return state.set('sessionId', action.sessionId);
    case actions.SET_TOKEN_EXPIRES_DATE:
      return state.set('tokenExpiresDate', action.tokenExpiresDate);
    case actions.SET_USER_COOKIES:
      return state.set('userCookies', action.userCookies);
    case actions.SET_USER_ID:
      return state.set('userId', action.userId);
    case actions.SET_USER_IP:
      return state.set('userIp', action.userIp);
    case actions.SIGN_IN:
      return state.set('signedIn', true);
    case actions.AUTHORIZE:
      return state.set('authorized', true);
    default:
      return state;
  }
};

export { actions, effects, selectors };
