import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp, theme } from 'styled-tools';

const Input = styled.input`
  background-color: #f4f4f4;
  border: none;
  box-shadow: ${ifProp(
    'error',
    css`
    0 0 0 2px ${theme('colors.error')} inset
  `,
    'none',
  )};
  border-radius: 24px;
  color: ${theme('colors.dark')};
  height: 48px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  outline: none;
  padding: 0 24px;
  width: calc(100% - 48px);
`;

export default ({ className, type = 'text', ...props }) => (
  <Input className={className} type={type} {...props} />
);
