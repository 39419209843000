import styled from 'styled-components';
import { ifProp } from 'styled-tools';

export default styled.div`
  > div {
    > h2 {
      margin: 4px 0;
    }
  }

  button {
    margin: 32px 24px 0 24px;
  }

  ${ifProp('fullWidth', 'width: 100%;')}

  @media (max-width: 768px) {
    button {
      margin-bottom: 64px;
    }
  }
`;
