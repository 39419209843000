import React from 'react';
import { Button } from '../../Button';
import Content from './_style';

import { Heading } from '../../_styled';

export default ({ onClose }) => (
  <Content>
    <div>
      <Heading.Title>Oops...</Heading.Title>
      <Heading.Title bold>
        A chave de acesso deve possuir 44 caracteres
      </Heading.Title>
    </div>
    <Button onClick={onClose}>CORRIGIR CHAVE</Button>
  </Content>
);
