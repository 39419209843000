import events from '..';

export default function (pagePath, pageTitle, emailHash) {
  return {
    type: events.batchConsultCancel,
    data: {
      emailHash,
      event: events.batchConsultCancel,
      pagePath,
      pageTitle,
    },
  };
}
