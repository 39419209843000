import Hero from './Hero';
import Subtitle from './Subtitle';
import Title from './Title';

const Heading = {};

Heading.Hero = Hero;
Heading.Subtitle = Subtitle;
Heading.Title = Title;

export default Heading;
