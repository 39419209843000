import React from 'react';
import styled from 'styled-components';
import { ifProp } from 'styled-tools';
import { Row, Col } from 'styled-bootstrap-grid';

import Navbar from '../Navbar';
import { LogoArea } from 'layouts/components/molecules';

const CustomCol = styled(Col)`
  padding: 0;
`;

const StyledCol = styled(CustomCol)`
  display: flex;
  justify-content: ${ifProp({ id: 'arquivei-navbar' }, 'flex-end')};

  @media screen and (max-width: 992px) {
    display: flex;
    justify-content: center;
  }
`;

const Header = () => (
  <React.Fragment>
    <Row id="arquivei-header">
      <StyledCol lg={3} sm={12} id="arquivei-logo">
        <LogoArea />
      </StyledCol>
      <StyledCol lg={9} sm={12} id="arquivei-navbar">
        <Navbar />
      </StyledCol>
    </Row>
  </React.Fragment>
);

export default Header;
