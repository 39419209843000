import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'styled-bootstrap-grid';

import { LogoArea } from 'layouts/components/molecules';

const CustomCol = styled(Col)`
  padding: 0;
`;

const StyledCol = styled(CustomCol)`
  display: flex;
  justify-content: ${(props) => props.logoAlignment};
`;

const Header = ({ logoAlignment = 'center' }) => (
  <Row id="arquivei-header">
    <StyledCol sm={12} id="arquivei-logo" logoAlignment={logoAlignment}>
      <LogoArea isLarge />
    </StyledCol>
  </Row>
);

export default Header;
