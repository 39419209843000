export const RESET_SINGLE_KEY_QUERY = 'single-key-query/RESET_SINGLE_KEY_QUERY';
export const resetSingleKeyQuery = () => ({
  type: RESET_SINGLE_KEY_QUERY,
});

export const SET_GOOGLE_RECAPTCHA_TOKEN = 'home/SET_GOOGLE_RECAPTCHA_TOKEN';
export const setGoogleRecaptchaToken = (googleRecaptchaToken) => ({
  type: SET_GOOGLE_RECAPTCHA_TOKEN,
  googleRecaptchaToken,
});

export const SET_GOOGLE_RECAPTCHA_WIDGET_ID =
  'home/SET_GOOGLE_RECAPTCHA_WIDGET_ID';
export const setGoogleRecaptchaWidgetId = (googleRecaptchaWidgetId) => ({
  type: SET_GOOGLE_RECAPTCHA_WIDGET_ID,
  googleRecaptchaWidgetId,
});

export const SET_NFE_ACCESS_KEY = 'single-key-query/SET_NFE_ACCESS_KEY';
export const setNfeAccessKey = (nfeAccessKey) => ({
  nfeAccessKey,
  type: SET_NFE_ACCESS_KEY,
});

export const SET_NFE_REQUEST_ID = 'single-key-query/SET_NFE_REQUEST_ID';
export const setNfeRequestId = (nfeRequestId) => ({
  nfeRequestId,
  type: SET_NFE_REQUEST_ID,
});

export const TOGGLE_FETCHING_NFE = 'single-key-query/TOGGLE_FETCHING_NFE';
export const toggleFetchingNfe = () => ({
  type: TOGGLE_FETCHING_NFE,
});

export const TOGGLE_GENERATING_BLOB_XML =
  'single-key-query/TOGGLE_GENERATING_BLOB_XML';
export const toggleGeneratingBlobXml = () => ({
  type: TOGGLE_GENERATING_BLOB_XML,
});
