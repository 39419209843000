import React from 'react';
import { Button } from '../../Button';
import Content from './_style';

import { Heading } from '../../_styled';

export default ({ onClose }) => (
  <Content>
    <div>
      <Heading.Title bold>Chave de acesso utilizada é inválida</Heading.Title>
    </div>
    <Button onClick={onClose}>CORRIGIR CHAVE DE ACESSO</Button>
  </Content>
);
