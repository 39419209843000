import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from '../_styled';
import { Input } from 'components/Input';

const InputSet = ({ data }) => (
  <div>
    {data.map((input) => (
      <div key={input.title}>
        <Heading.Subtitle small>{input.title}</Heading.Subtitle>
        <Input
          type={input.text || 'text'}
          placeholder={input.placeholder || ''}
        />
      </div>
    ))}
  </div>
);

InputSet.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      placeholder: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
};

InputSet.defaultProps = {
  data: [],
};

export default InputSet;
