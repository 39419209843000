import React from 'react';
import { connect } from 'react-redux';
import { withTracking } from 'react-tracker';
import { Button } from '../../Button';
import Content from './_style';

import { Heading } from '../../_styled';

import { parse } from 'utils';
import { DOCUMENT_DOWNLOAD_URL } from 'utils/endpoints';
import singleConsultDownloadPdfEvent from 'utils/tracking/events/singleConsultDownloadPdf';
import singleConsultDownloadXmlEvent from 'utils/tracking/events/singleConsultDownloadXml';

import { effects, selectors } from 'store/single-key-query';

const HomeSuccess = ({
  handleResetSingleKeyQuery,
  initializeGoogleRecaptcha,
  isDownloadingXml,
  onClose,
  onDownloadXml,
  requestId,
  trackDownloadPdf,
  trackDownloadXml,
}) => {
  const handleClose = () => {
    handleResetSingleKeyQuery();
    initializeGoogleRecaptcha();
    onClose();
  };

  const DOWNLOAD_PDF_URL = parse(DOCUMENT_DOWNLOAD_URL, { type: 'pdf' });

  return (
    <Content>
      <div>
        <Heading.Title bold>Nota encontrada</Heading.Title>
      </div>
      <Button
        isLoading={isDownloadingXml}
        onClick={onDownloadXml}
        tracking={trackDownloadXml}
        variant="success"
      >
        BAIXAR O XML
      </Button>
      <Button
        href={parse(DOWNLOAD_PDF_URL, { requestId })}
        tracking={trackDownloadPdf}
        variant="success"
      >
        IMPRIMIR DANFE
      </Button>
      <Button onClick={handleClose}>FAZER NOVA CONSULTA</Button>
    </Content>
  );
};

const mapStateToProps = (state) => ({
  isDownloadingXml: selectors.getIsGeneratingBlobXml(state),
  requestId: selectors.getNfeRequestId(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleResetSingleKeyQuery: () =>
    dispatch(effects.handleResetSingleKeyQuery()),
  initializeGoogleRecaptcha: () =>
    dispatch(effects.initializeGoogleRecaptcha()),
  onDownloadXml: () => dispatch(effects.downloadXml()),
});

const mapTrackingToProps = (trackEvent) => ({
  trackDownloadPdf: () => {
    trackEvent(singleConsultDownloadPdfEvent('/', 'Home'));
  },
  trackDownloadXml: () => {
    trackEvent(singleConsultDownloadXmlEvent('/', 'Home'));
  },
});

export default withTracking(mapTrackingToProps)(
  connect(mapStateToProps, mapDispatchToProps)(HomeSuccess),
);
