import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { selectors as userSessionSelectors } from 'store/user-session';
import { Divider } from 'layouts/components/atoms';
import {
  AnonymousUserArea,
  ExternalLinksArea,
  LoggedUserArea,
} from './NavbarArea';

const WrapperNavbar = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
  padding: 24px 0px;

  & > * {
    margin-right: 32px;
  }

  @media screen and (max-width: 992px) {
    padding-top: 0px;

    & > *:last-child {
      margin-right: 0px;
    }
  }
`;

const Navbar = ({ isLoggedUser }) => (
  <WrapperNavbar>
    <ExternalLinksArea hideMd />
    <Divider hideMd />
    {isLoggedUser ? <LoggedUserArea /> : <AnonymousUserArea />}
  </WrapperNavbar>
);

const mapStateToProps = (state) => ({
  isLoggedUser: userSessionSelectors.getSignedIn(state),
});

export default connect(mapStateToProps)(Navbar);
