import React from 'react';
import styled from 'styled-components';
import { theme, prop } from 'styled-tools';
import { Button } from '../Button';
import commonStyle from './style/common';

const Card = styled.div`
  width: ${prop('width', '151px')};
  height: ${prop('width', '139px')};

  background-color: ${theme('colors.primary')};

  .card-action {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title {
    color: ${theme('colors.white')};
    font-size: ${theme('text.cta.fontSize')};

    > .featured {
      font-size: ${theme('text.title.fontSize')};
    }

    font-weight: bold;
  }

  .content {
    color: ${theme('colors.white')};
    font-size: ${theme('text.cta.fontSize')};
    font-weight: bold;

    > .featured {
      font-size: ${theme('text.title.fontSize')};
    }
  }

  ${commonStyle}
`;

export default ({
  className,
  action,
  btnLabel = '',
  title = '',
  content = '',
  tracking,
  ...props
}) => (
  <Card className={className} {...props}>
    <div className="title">{title}</div>
    <div className="content">{content}</div>
    <div className="card-action">
      <Button
        onClick={action}
        variant="primary-inverse"
        tracking={typeof tracking === 'function' && tracking}
      >
        {btnLabel}
      </Button>
    </div>
  </Card>
);
