export default {
  hero: {
    fontSize: '42px',
  },
  title: {
    fontSize: '22px',
  },
  body: {
    fontSize: '18px',
    lineHeight: '27px',
  },
  cta: {
    fontSize: '14px',
  },
};
