import events from '..';
import track from '../gtm';

function trackUserSignIn(event) {
  track(event.data);
  return event;
}
trackUserSignIn.eventType = events.userSignIn;

export default trackUserSignIn;
