import React from 'react';
import { Button } from '../../Button';
import Content from './_style';

import { Heading } from '../../_styled';

export default ({ onClose }) => (
  <Content>
    <div>
      <Heading.Title bold>
        Chaves de acesso utilizadas são inválidas
      </Heading.Title>
    </div>
    <Button onClick={onClose}>CORRIGIR</Button>
  </Content>
);
