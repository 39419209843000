export const RESET_USER_AUTHENTICATION =
  'user-authentication/RESET_USER_AUTHENTICATION';
export const resetUserAuthentication = () => ({
  type: RESET_USER_AUTHENTICATION,
});

export const SET_OOB_CODE = 'user-authentication/SET_OOB_CODE';
export const setOobCode = (oobCode) => ({
  oobCode,
  type: SET_OOB_CODE,
});

export const SET_USER_EMAIL = 'user-authentication/SET_USER_EMAIL';
export const setUserEmail = (userEmail) => ({
  type: SET_USER_EMAIL,
  userEmail,
});

export const SET_USER_EMAIL_ERROR = 'user-authentication/SET_USER_EMAIL_ERROR';
export const setUserEmailError = (userEmailError) => ({
  type: SET_USER_EMAIL_ERROR,
  userEmailError,
});

export const SET_USER_PASSWORD = 'user-authentication/SET_USER_PASSWORD';
export const setUserPassword = (userPassword) => ({
  type: SET_USER_PASSWORD,
  userPassword,
});

export const SET_USER_PASSWORD_ERROR =
  'user-authentication/SET_USER_PASSWORD_ERROR';
export const setUserPasswordError = (userPasswordError) => ({
  type: SET_USER_PASSWORD_ERROR,
  userPasswordError,
});

export const TOGGLE_EMAIL_VERIFICATION =
  'user-authentication/TOGGLE_EMAIL_VERIFICATION';
export const toggleEmailVerification = () => ({
  type: TOGGLE_EMAIL_VERIFICATION,
});

export const TOGGLE_PASSWORD_RESET =
  'user-authentication/TOGGLE_PASSWORD_RESET';
export const togglePasswordReset = () => ({
  type: TOGGLE_PASSWORD_RESET,
});
