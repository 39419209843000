import events from '..';

export default function (pagePath, pageTitle, destinationPage) {
  return {
    type: events.externalArquivei,
    data: {
      destinationPage,
      event: events.externalArquivei,
      pagePath,
      pageTitle,
    },
  };
}
