import React from 'react';
import { Button } from '../../Button';
import Content from './_style';

import { Heading } from '../../_styled';

export default ({ onClose }) => (
  <Content>
    <div>
      <Heading.Title bold>
        Você está pronto para redefinir sua senha
      </Heading.Title>
      <Heading.Title>
        Faça o login com sua nova senha para fazer a alteração
      </Heading.Title>
    </div>
    <Button onClick={onClose}>ENTENDI</Button>
  </Content>
);
