import React from 'react';
import { connect } from 'react-redux';
import { withTracking } from 'react-tracker';
import { Button } from '../../Button';
import Content from './_style';

import { Heading } from '../../_styled';

import batchConsultEvent from 'utils/tracking/events/batchConsult';
import batchConsultCancelEvent from 'utils/tracking/events/batchConsultCancel';
import { generateUserEmailHash } from 'utils/userAuth';

import { selectors } from 'store/batch';

const BatchConfirm = ({
  batchSize,
  onClose,
  promiseHandler,
  trackCancel,
  trackConfirm,
}) => {
  const handleConsult = () => {
    if (typeof promiseHandler.get('resolve') === 'function') {
      promiseHandler.get('resolve')();
    }
    onClose();
  };

  const handleClose = () => {
    if (typeof promiseHandler.get('reject') === 'function') {
      promiseHandler.get('reject')();
    }
    onClose();
  };

  return (
    <Content>
      <div>
        <Heading.Title bold>
          {`Identificamos ${batchSize} chave${
            batchSize !== 1 ? 's' : ''
          } de acesso no seu lote.`}
        </Heading.Title>
        <Heading.Title bold>Quer consultá-las agora?</Heading.Title>
      </div>
      <Button onClick={handleClose} tracking={trackCancel}>
        VOLTAR
      </Button>
      <Button onClick={handleConsult} variant="success" tracking={trackConfirm}>
        CONSULTAR AGORA
      </Button>
    </Content>
  );
};

const mapStateToProps = (state) => ({
  batchSize: selectors.getBatchSize(state),
});

const mapTrackingToProps = (trackEvent) => ({
  trackCancel: () => {
    trackEvent(
      batchConsultCancelEvent(
        '/batch',
        'Consulta em lote',
        generateUserEmailHash(),
      ),
    );
  },
  trackConfirm: () => {
    trackEvent(
      batchConsultEvent('/batch', 'Consulta em lote', generateUserEmailHash()),
    );
  },
});

export default withTracking(mapTrackingToProps)(
  connect(mapStateToProps)(BatchConfirm),
);
