import styled, { css } from 'styled-components';
import { ifProp, ifNotProp, switchProp, theme } from 'styled-tools';

export default styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  border-radius: ${ifProp('small', '16px', '24px')};
  cursor: pointer;
  font-family: 'Rubik', sans-serif;
  font-size: ${theme('text.cta.fontSize')};
  font-weight: bold;
  min-height: ${ifProp('small', '33px', '40px')};
  min-width: 53px;
  padding: ${ifProp('small', '0 8px', '0 32px')};
  outline: none;
  text-decoration: none;

  ${switchProp(
    'variant',
    {
      success: css`
        border: none;
        background-color: ${theme('colors.success')};
        color: ${theme('colors.white', 'inherit')};

        ${ifNotProp(
          ['disabled', 'noHover'],
          css`
            &:hover {
              background-color: ${theme('colors.successHover', 'inherit')};
            }
          `,
        )}
      `,
      error: css`
        border: none;
        background-color: ${theme('colors.error', 'inherit')};
        color: ${theme('colors.white', 'inherit')};
      `,
      alert: css`
        border: none;
        background-color: ${theme('colors.alert', 'inherit')};
        color: ${theme('colors.white', 'inherit')};
      `,
      dark: css`
        border: none;
        background-color: ${theme('colors.dark', 'inherit')};
        color: ${theme('colors.white', 'inherit')};
      `,
      extraDark: css`
        border: none;
        background-color: ${theme('colors.extraDark', 'inherit')};
        color: ${theme('colors.white', 'inherit')};
      `,
      gray300: css`
        border: none;
        background-color: ${theme('colors.gray300', 'inherit')};
        color: ${theme('colors.white', 'inherit')};
      `,
      gray200: css`
        border: none;
        background-color: ${theme('colors.gray200', 'inherit')};
        color: ${theme('colors.white', 'inherit')};
      `,
      'gray200-outline': css`
        border: none;
        box-shadow: 0 0 0 2px ${theme('colors.gray200', 'inherit')} inset;
        background-color: ${theme('colors.white', 'inherit')};
        color: ${theme('colors.gray200', 'inherit')};
      `,
      'primary-outline': css`
        border: none;
        box-shadow: 0 0 0 2px ${theme('colors.primary', 'inherit')} inset;
        background-color: ${theme('colors.white', 'inherit')};
        color: ${theme('colors.primary', 'inherit')};
      `,
      'primary-inverse': css`
        border: none;
        background-color: ${ifProp(
          'isLoading',
          theme('colors.primaryHover'),
          theme('colors.white'),
        )};
        color: ${theme('colors.primary', 'inherit')};

        ${ifNotProp(
          ['disabled', 'noHover'],
          css`
            &:hover {
              background-color: ${theme('colors.primaryHover', 'inherit')};
              color: ${theme('colors.white', 'inherit')};
            }
          `,
        )}
      `,
      primary: css`
        border: none;
        background-color: ${ifProp(
          'isLoading',
          theme('colors.primaryHover'),
          theme('colors.primary'),
        )};
        color: ${theme('colors.white', 'inherit')};

        ${ifNotProp(
          ['disabled', 'noHover'],
          css`
            &:hover {
              background-color: ${theme('colors.primaryHover', 'inherit')};
            }
          `,
        )}
      `,
    },
    css`
      border: none;
      background-color: ${ifProp(
        'isLoading',
        theme('colors.primaryHover'),
        theme('colors.primary'),
      )};
      color: ${theme('colors.white', 'inherit')};

      ${ifNotProp(
        ['disabled', 'noHover'],
        css`
          &:hover {
            background-color: ${theme('colors.primaryHover', 'inherit')};
          }
        `,
      )}
    `,
  )}

  span {
    visibility: ${ifProp('isLoading', 'hidden', 'visible')};
  }

  svg {
    position: absolute;
    visibility: ${ifProp('isLoading', 'visible', 'hidden')};
  }

  ${ifProp(
    'disabled',
    css`
      background-color: ${theme('colors.gray200', 'inherit')}!important;
    `,
  )}
`;
