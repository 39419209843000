/* eslint-disable no-new */
import md5 from 'md5';

import { auth } from './firebase';

export function generateUserEmailHash(email = '') {
  let hash;

  if (email) {
    return md5(email);
  }

  try {
    hash = md5(auth.currentUser.email);
  } catch (error) {
    console.log('User not authenticated');
  }

  return hash;
}
