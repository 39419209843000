import React from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import { Row, Col } from 'styled-bootstrap-grid';
import PlainButton from 'components/Button/PlainButton';

const EXTERNAL_URL_TERMS = 'https://arquivei.com.br/lite/termos/';
const EXTERNAL_URL_MORE_THAN_LITE = 'https://arquivei.com.br/lite/maisquelite/';

const Terms = styled.div`
  height: 25px;
  background-color: ${theme('colors.extraDark')};
  padding: 0 32px;
  font-size: 14px;
  font-weight: normal;

  > div {
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    color: white;
  }

  .mobile {
    display: none;
    @media (max-width: 768px) {
      display: block;
    }
  }
`;

const TermsLink = styled(PlainButton)`
  font-size: 14px;
  font-weight: normal;
  text-decoration: underline;
`;

export default (props) => (
  <Terms {...props}>
    <Row className="terms">
      <Col sm={12} md={3}>
        <TermsLink href={EXTERNAL_URL_TERMS} variant="white">
          Termos de Uso e Política de Privacidade
        </TermsLink>
      </Col>
      <Col sm={12} md={4} className="mobile">
        Possui Certificado Digital? Temos a{' '}
        {
          <PlainButton
            href={EXTERNAL_URL_MORE_THAN_LITE}
            style={{ fontSize: 'inherit', textDecoration: 'underline' }}
            variant="alert"
          >
            solução ideal para você
          </PlainButton>
        }
        !
      </Col>
    </Row>
  </Terms>
);
