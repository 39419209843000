import React from 'react';
import { connect } from 'react-redux';
import { withTracking } from 'react-tracker';
import { Button } from '../../Button';
import Content from './_style';

import { Heading } from '../../_styled';

import buyMoreCreditsEvent from 'utils/tracking/events/buyMoreCredits';
import { generateUserEmailHash } from 'utils/userAuth';
import { selectors as batchSelectors } from 'store/batch';
import { actions as notificationCardActions } from 'store/notification-card-deprecated';

const BatchLimitExceeded = ({
  onClose,
  purchaseCredits,
  trackBuyMoreCredits,
  remainingCredits = 0,
}) => {
  const onPurchase = () => {
    onClose();
    trackBuyMoreCredits('/batch', 'Consulta em lote');
    purchaseCredits();
  };

  return (
    <Content>
      <div>
        <Heading.Title bold>
          {`Seu saldo atual é de ${remainingCredits} chave${
            remainingCredits !== 1 ? 's' : ''
          }`}
        </Heading.Title>
        <Heading.Title bold>
          Adquira mais chaves para continuar consultando
        </Heading.Title>
      </div>
      <Button onClick={onClose}>VOLTAR</Button>
      <Button target="_blank" onClick={onPurchase} variant="success">
        COMPRAR MAIS CHAVES
      </Button>
    </Content>
  );
};

const mapStateToProps = (state) => ({
  remainingCredits: batchSelectors.getRemainingBatch(state),
});

const mapDispatchToProps = (dispatch) => ({
  purchaseCredits: () => {
    dispatch(
      notificationCardActions.setNotificationCardContext('purchase-credits'),
    );
    setTimeout(
      () => dispatch(notificationCardActions.toggleNotificationCard()),
      200,
    );
  },
});

const mapTrackingToProps = (trackEvent) => ({
  trackBuyMoreCredits: (pagePath, pageTitle) => {
    trackEvent(
      buyMoreCreditsEvent(pagePath, pageTitle, generateUserEmailHash()),
    );
  },
});

export default withTracking(mapTrackingToProps)(
  connect(mapStateToProps, mapDispatchToProps)(BatchLimitExceeded),
);
