import { Map } from 'immutable';

import * as actions from './actions';
import * as effects from './effects';
import * as selectors from './selectors';

const scaffold = Map({
  googleRecaptchaToken: '',
  googleRecaptchaWidgetId: '',
  isFetchingNfe: false,
  isGeneratingBlobXml: false,
  nfeAccessKey: '',
  nfeRequestId: '',
});

export default (state = scaffold, action) => {
  switch (action.type) {
    case actions.RESET_SINGLE_KEY_QUERY:
      return scaffold;
    case actions.SET_GOOGLE_RECAPTCHA_TOKEN:
      return state.set('googleRecaptchaToken', action.googleRecaptchaToken);
    case actions.SET_GOOGLE_RECAPTCHA_WIDGET_ID:
      return state.set(
        'googleRecaptchaWidgetId',
        action.googleRecaptchaWidgetId,
      );
    case actions.SET_NFE_ACCESS_KEY:
      return state.set('nfeAccessKey', action.nfeAccessKey);
    case actions.SET_NFE_REQUEST_ID:
      return state.set('nfeRequestId', action.nfeRequestId);
    case actions.TOGGLE_FETCHING_NFE:
      return state.update('isFetchingNfe', (value) => !value);
    case actions.TOGGLE_GENERATING_BLOB_XML:
      return state.update('isGeneratingBlobXml', (value) => !value);
    default:
      return state;
  }
};

export { actions, effects, selectors };
