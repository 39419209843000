import { Map } from 'immutable';

import * as actions from './actions';
import * as effects from './effects';
import * as selectors from './selectors';

const scaffold = Map({
  notificationCardContext: 'home-success',
  notificationCardIsOpen: false,
  promiseHandler: Map(),
});

export default (state = scaffold, action) => {
  switch (action.type) {
    case actions.SET_NOTIFICATION_CARD_CONTEXT:
      return state.set('notificationCardContext', action.context);
    case actions.SET_NOTIFICATION_PROMISE_RESOLVE:
      return state
        .setIn(['promiseHandler', 'resolve'], action.resolve)
        .setIn(['promiseHandler', 'reject'], action.reject);
    case actions.TOGGLE_NOTIFICATION_CARD:
      return typeof action.value === 'boolean'
        ? state.set('notificationCardIsOpen', action.value)
        : state.update('notificationCardIsOpen', (isOpen) => !isOpen);
    default:
      return state;
  }
};

export { actions, effects, selectors };
