import React from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';

const TextArea = styled.textarea`
  background-color: #f4f4f4;
  border: none;
  border-radius: 24px;
  color: ${theme('colors.dark')};
  height: 48px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  outline: none;
  padding: 24px;
  min-height: 232px;
  resize: none;
  text-align: center;
  max-width: 650px;
  width: 100%;
`;

export default ({ className, ...props }) => (
  <TextArea className={className} {...props} />
);
