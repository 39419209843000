import React from 'react';
import styled from 'styled-components';
import { ifProp, theme } from 'styled-tools';

const RadioInput = styled('input')`
  display: none;

  + label > div {
    border: 1px solid ${theme('colors.primary')};
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px;
    height: 12px;
    width: 12px;

    > div {
      background-color: ${theme('colors.primary')};
      border-radius: 50%;
      display: none;
      left: 3px;
      top: 3px;
      position: relative;
      height: 6px;
      width: 6px;
    }

    &:hover {
      border-color: #1f3a7c;
    }
  }

  &:checked + label > div {
    border: 1px solid ${theme('colors.primary')};

    > div {
      display: block;
    }
  }
`;

const RadioLabel = styled('label')`
  margin-bottom: ${ifProp('noMargin', '8px', '18px')};

  font-size: 18px;
  cursor: pointer;
  font-weight: normal;
  vertical-align: middle;

  > span {
    line-height: 22px;
    vertical-align: text-bottom;
  }
`;

const Radio = ({ id, label, name, noMargin = false, value, ...rest }) => (
  <React.Fragment>
    <RadioInput id={id} name={name} type="radio" value={value} {...rest} />
    <RadioLabel htmlFor={id} noMargin={noMargin}>
      <div className="outer-circle">
        <div className="inner-circle" />
      </div>
      <span>{label}</span>
    </RadioLabel>
  </React.Fragment>
);

export default Radio;
