import React from 'react';
import { Button } from '../../Button';
import Content from './_style';

import { Heading } from '../../_styled';

export default ({ onClose }) => (
  <Content>
    <div>
      <Heading.Title bold>Quantidade de chaves excedida</Heading.Title>
      <Heading.Title bold>
        Você pode incluir até 300 notas em cada lote
      </Heading.Title>
    </div>
    <Button onClick={onClose}>CORRIGIR</Button>
  </Content>
);
