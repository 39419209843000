export const CHANGE_CURRENT_TAB = 'batch/CHANGE_CURRENT_TAB';
export const changeCurrentTab = (tab) => ({
  tab,
  type: CHANGE_CURRENT_TAB,
});

export const SET_BAD_FORMAT_LINES = 'batch/SET_BAD_FORMAT_LINES';
export const setBadFormatLines = (value) => ({
  type: SET_BAD_FORMAT_LINES,
  value,
});

export const SET_DUPLICATED_LINES = 'batch/SET_DUPLICATED_LINES';
export const setDuplicatedLines = (value) => ({
  type: SET_DUPLICATED_LINES,
  value,
});

export const SET_BATCH_SIZE = 'batch/SET_BATCH_SIZE';
export const setBatchSize = (value) => ({
  type: SET_BATCH_SIZE,
  value,
});

export const SET_REMAINING_BATCH = 'batch/SET_REMAINING_BATCH';
export const setRemainingBatch = (value) => ({
  type: SET_REMAINING_BATCH,
  value,
});

export const SET_REPORT_DATA = 'batch/SET_REPORT_DATA';
export const setReportData = (data) => ({
  data,
  type: SET_REPORT_DATA,
});

export const SET_PURCHASE_DATA = 'batch/SET_PURCHASE_DATA';
export const setPurchaseData = (data) => ({
  data,
  type: SET_PURCHASE_DATA,
});

export const SET_CREDITS = 'batch/SET_CREDITS';
export const setCredits = (userCredits) => ({
  userCredits,
  type: SET_CREDITS,
});

export const PUSH_ZIP_REQUEST_ID = 'batch/PUSH_ZIP_REQUEST_ID';
export const pushZipRequestId = (id) => ({
  id,
  type: PUSH_ZIP_REQUEST_ID,
});

export const DELETE_ZIP_REQUEST_ID = 'batch/DELETE_ZIP_REQUEST_ID';
export const deleteZipRequestId = (id) => ({
  id,
  type: DELETE_ZIP_REQUEST_ID,
});

export const TOGGLE_BATCH_LOADING = 'batch/TOGGLE_BATCH_LOADING';
export const toggleBatchLoading = () => ({
  type: TOGGLE_BATCH_LOADING,
});

export const TOGGLE_PURCHASE_LOADING = 'batch/TOGGLE_PURCHASE_LOADING';
export const togglePurchaseLoading = (value) => ({
  type: TOGGLE_PURCHASE_LOADING,
  value,
});

export const TOGGLE_DOWNLOADING_ZIP = 'batch/TOGGLE_DOWNLOADING_ZIP';
export const toggleDownloadingZip = () => ({
  type: TOGGLE_DOWNLOADING_ZIP,
});

export const TOGGLE_FETCH_CREDITS = 'batch/TOGGLE_FETCH_CREDITS';
export const toggleFetchCredits = (value) => ({
  type: TOGGLE_FETCH_CREDITS,
  value,
});

export const TOGGLE_FETCH_REPORT = 'batch/TOGGLE_FETCH_REPORT';
export const toggleFetchReport = () => ({
  type: TOGGLE_FETCH_REPORT,
});

export const TOGGLE_FETCH_PURCHASES = 'batch/TOGGLE_FETCH_PURCHASES';
export const toggleFetchPurchases = () => ({
  type: TOGGLE_FETCH_PURCHASES,
});
