import React from 'react';
import { connect } from 'react-redux';
import { Button } from '../../Button';
import Content from './_style';

import { Heading } from '../../_styled';

import { actions as batchActions } from 'store/batch';
import { actions as notificationCardActions } from 'store/notification-card-deprecated';

const HomeSuccess = ({ onChangeTab, onClose }) => (
  <Content>
    <div>
      <Heading.Title>
        Estamos gerando seu lote e isso pode levar alguns minutos...
      </Heading.Title>
      <Heading.Title bold>
        Baixe o ZIP na aba &quot;Meus Lotes&quot;
      </Heading.Title>
    </div>
    <Button onClick={onClose}>ENTENDI</Button>
    <Button onClick={onChangeTab} variant="success">
      IR PARA MEUS LOTES
    </Button>
  </Content>
);

const mapDispatchToProps = (dispatch) => ({
  onChangeTab: () => {
    dispatch(notificationCardActions.toggleNotificationCard());
    dispatch(batchActions.changeCurrentTab('batch-reports'));
  },
});

export default connect(null, mapDispatchToProps)(HomeSuccess);
