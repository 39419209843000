import styled from 'styled-components';
import { theme } from 'styled-tools';

export default styled.h1`
  font-size: ${theme('text.hero.fontSize')};
  font-weight: bold;
  line-height: 1.14;
  text-align: center;
  color: ${theme('colors.dark')};

  @media (max-height: 600px) {
    font-size: 36px;
  }
`;
