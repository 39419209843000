import React from 'react';
import { PlainButton } from './style';

const track = (event, tracking, callback, href = '') => {
  if (typeof tracking === 'function') {
    tracking();
  }

  if (typeof callback === 'function') {
    callback(event);
  }

  if (href) {
    window.open(href, '_blank');
  }
};

const Button = ({
  children,
  className,
  href = '',
  onClick,
  tracking,
  ...props
}) => (
  <PlainButton
    className={className}
    onClick={(event) => {
      track(event, tracking, onClick, href);
    }}
    {...props}
  >
    {children}
  </PlainButton>
);

Button.displayName = 'PlainButton';

export default Button;
