import React from 'react';
import { connect } from 'react-redux';
import { Button } from '../../Button';
import Content from './_style';

import { Heading } from '../../_styled';

import { selectors } from 'store/batch';

const BatchAcceptDuplicates = ({
  duplicatedLines,
  onClose,
  promiseHandler,
}) => {
  const handleConsult = () => {
    if (typeof promiseHandler.get('resolve') === 'function') {
      promiseHandler.get('resolve')();
    }
    onClose();
  };

  const handleClose = () => {
    if (typeof promiseHandler.get('reject') === 'function') {
      promiseHandler.get('reject')();
    }
    onClose();
  };

  return (
    <Content>
      <div>
        <Heading.Title bold>
          {`Identificamos ${duplicatedLines} chave${
            duplicatedLines !== 1 ? 's' : ''
          } repetida${duplicatedLines !== 1 ? 's' : ''} que não ser${
            duplicatedLines !== 1 ? 'ão' : 'á'
          } processada${duplicatedLines !== 1 ? 's' : ''}.`}
        </Heading.Title>
        <Heading.Title bold>
          Deseja continuar a consulta para as demais chaves?
        </Heading.Title>
      </div>
      <Button onClick={handleClose}>CORRIGIR</Button>
      <Button onClick={handleConsult} variant="success">
        CONSULTAR MESMO ASSIM
      </Button>
    </Content>
  );
};

const mapStateToProps = (state) => ({
  duplicatedLines: selectors.getDuplicatedLines(state),
});

export default connect(mapStateToProps)(BatchAcceptDuplicates);
