const events = {
  // user
  userSignIn: 'user-has-signed-in',
  userSignUp: 'user-has-signed-up',

  // single consult
  singleConsult: 'single-consult-button-click',
  singleConsultCte: 'single-consult-cte-access-key',
  singleConsultSuccess: 'single-consult-success',
  singleConsultError: 'single-consult-error',
  singleConsultDownloadXml: 'download-xml-single-consult-button-click',
  singleConsultDownloadPdf: 'view-pdf-single-consult-button-click',

  // batch
  batchConsult: 'batch-consult-button-click',
  batchConsultCancel: 'batch-consult-button-cancel',
  batchConsultPreConfirm: 'batch-consult-pre-confirm',
  batchConsultSuccess: 'batch-consult-success',
  batchConsultError: 'batch-consult-error',
  batchDownloadZip: 'download-zip-batch-consult-button-click',

  // purchase
  buyMoreCredits: 'buy-more-credits-button-click',

  // routing
  pageView: 'pageview',
  routeToBatch: 'go-to-batch-consult-button-click',

  // external urls
  externalArquivei: 'arquivei-button-click',

  // intercom
  openIntercom: 'open-intercom',

  // firebase error
  firebaseError: 'firebase-error',
};

const trackClick = (trackingCallback, route, destination) => {
  const routes = {
    '/': 'Home',
    '/batch': 'Consulta em lote',
  };

  trackingCallback(route, routes[route], destination);
};

export { trackClick };

export default events;
