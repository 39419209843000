import React from 'react';
import styled from 'styled-components';

const StyledLogo = styled.img`
  height: 100%;
  width: 100%;
`;

const Logo = () => (
  <StyledLogo src="/static/logo-qive.svg" alt="Qive Lite - Home" />
);

export default Logo;
