import React from 'react';
import styled from 'styled-components';
import { ifProp } from 'styled-tools';

import { withRouter } from 'next/router';
import { withTracking } from 'react-tracker';
import { trackClick } from 'utils/tracking';
import externalArquiveiEvent from 'utils/tracking/events/externalArquivei';

import NavbarItem from '../../NavbarItem';

const EXTERNAL_URL_MORE_THAN_LITE = 'https://arquivei.com.br/lite/maisquelite/';
const EXTERNAL_URL_FAQ = 'https://arquivei.com.br/lite/faq/';

const ExternalAreaItem = styled(NavbarItem)`
  @media screen and (max-width: 768px) {
    display: ${ifProp('hideMd', 'none')};
  }
`;

const ExternalArea = ({ router, trackExternalArquiveiClick, ...props }) => (
  <React.Fragment>
    <ExternalAreaItem
      href={EXTERNAL_URL_MORE_THAN_LITE}
      title="Consulte com\nCertificado Digital"
      tracking={() =>
        trackClick(
          trackExternalArquiveiClick,
          router.route,
          EXTERNAL_URL_MORE_THAN_LITE,
        )
      }
      type="external"
      {...props}
    />
    <ExternalAreaItem
      href={EXTERNAL_URL_FAQ}
      title="Perguntas\nFrequentes"
      tracking={() =>
        trackClick(trackExternalArquiveiClick, router.route, EXTERNAL_URL_FAQ)
      }
      type="external"
      {...props}
    />
  </React.Fragment>
);

const mapTrackingToProps = (trackEvent) => ({
  trackExternalArquiveiClick: (pagePath, pageTitle, destinationPage) => {
    trackEvent(externalArquiveiEvent(pagePath, pageTitle, destinationPage));
  },
});

export default withTracking(mapTrackingToProps)(withRouter(ExternalArea));
