import React from 'react';
import styled from 'styled-components';

const WrapperContent = styled.div`
  height: 100%;
  margin-bottom: 120px;

  @media (max-width: 768px) {
    margin-bottom: 60px;
  }
`;

const Content = ({ children }) => (
  <WrapperContent id="arquivei-content">{children}</WrapperContent>
);

export default Content;
