import events from '..';
import track from '../gtm';

function trackUserSignUp(event) {
  track(event.data);
  return event;
}
trackUserSignUp.eventType = events.userSignUp;

export default trackUserSignUp;
