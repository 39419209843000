import events from '..';

export default function (error) {
  return {
    type: events.firebaseError,
    data: {
      error,
      event: events.firebaseError,
    },
  };
}
