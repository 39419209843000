import { css } from 'styled-components';
import { ifProp } from 'styled-tools';

export default css`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 16px;
  border-radius: 8px;
  text-align: center;
  margin-top: 4px;
  margin-bottom: 4px;

  ${ifProp(
    'fixed',
    css`
      @media (min-width: 1280px) {
        border-top-left-radius: 8px;
        border-top-right-radius: 0;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 0;
      }
    `,
  )}
`;
