import Router from 'next/router';
import { fromJS } from 'immutable';
import FileSaver from 'file-saver';
import axios from 'axios';
import * as Sentry from '@sentry/browser';

import * as actions from './actions';
import * as selectors from './selectors';
import { actions as notificationCardActions } from 'store/notification-card-deprecated';
import {
  selectors as userSessionSelectors,
  effects as userSessionEffects,
} from 'store/user-session';

import { effects as userAuthenticationEffects } from 'store/user-authentication';

import { generateUserEmailHash } from 'utils/userAuth';
import { isTokenExpired } from 'utils/token';
import { parse } from 'utils';
import {
  DOCUMENT_LIST_URL,
  DOCUMENT_MULTI_SEARCH_URL,
  FETCH_CREDITS,
  PAGSEGURO_CHECKOUT,
  PURCHASE_CREDITS_URL,
  PURCHASE_LIST_URL,
  ZIP_DOWNLOAD_URL,
} from './endpoints';
import api from 'utils/api';

const handleUnauthorized = (error, errorMethod) => (dispatch, getState) => {
  Sentry.withScope((scope) => {
    scope.setTag('method', errorMethod);

    scope.setUser({
      id: userSessionSelectors.getUserId(getState()),
    });

    Sentry.captureException(error);
  });

  dispatch(
    notificationCardActions.setNotificationCardContext(
      'account-session-expired',
    ),
  );
  dispatch(notificationCardActions.toggleNotificationCard(true));
};

const refreshTokenIfNeeded = async (dispatch, getState) => {
  const tokenExpiresDate = userSessionSelectors.getTokenExpiresDate(getState());

  if (isTokenExpired(tokenExpiresDate)) {
    await dispatch(userAuthenticationEffects.handleRefreshToken());
  }
};

export const fetchReportData = () => async (dispatch, getState) => {
  dispatch(actions.toggleFetchReport());

  try {
    await refreshTokenIfNeeded(dispatch, getState);
  } catch (error) {
    dispatch(handleUnauthorized(error, 'fetchReportData'));
    return;
  }

  try {
    const res = await api({
      method: 'POST',
      url: DOCUMENT_LIST_URL,
      data: {
        firebaseToken: userSessionSelectors.getSessionId(getState()),
        userId: userSessionSelectors.getUserId(getState()),
      },
    });

    dispatch(actions.setReportData(fromJS(res.data.data)));
  } catch (err) {
    console.error(err);
    const status = err.response && err.response.status;
    if (status === 401) {
      dispatch(handleUnauthorized(err, 'fetchReportData'));
    }

    dispatch(actions.setReportData('UNKNOWN_ERROR'));
  } finally {
    dispatch(actions.toggleFetchReport());
  }
};

export const fetchPurchaseData = () => async (dispatch, getState) => {
  dispatch(actions.toggleFetchPurchases());

  try {
    await refreshTokenIfNeeded(dispatch, getState);
  } catch (error) {
    dispatch(handleUnauthorized(error, 'fetchPurchaseData'));
    return;
  }

  try {
    const res = await api({
      method: 'POST',
      url: PURCHASE_LIST_URL,
      data: {
        firebaseToken: userSessionSelectors.getSessionId(getState()),
        userId: userSessionSelectors.getUserId(getState()),
      },
    });

    dispatch(actions.setPurchaseData(fromJS(res.data.data)));
  } catch (err) {
    console.error(err);
    const status = err.response && err.response.status;
    if (status === 401) {
      dispatch(handleUnauthorized(err, 'fetchPurchaseData'));
    }

    dispatch(actions.setPurchaseData('UNKNOWN_ERROR'));
  } finally {
    dispatch(actions.toggleFetchPurchases());
  }
};

export const purchaseCredits = (itemNominal) => async (dispatch, getState) => {
  dispatch(actions.togglePurchaseLoading());

  try {
    await refreshTokenIfNeeded(dispatch, getState);
  } catch (error) {
    dispatch(handleUnauthorized(error, 'purchaseCredits'));
    return;
  }

  try {
    const res = await api({
      method: 'POST',
      url: PURCHASE_CREDITS_URL,
      data: {
        firebaseToken: userSessionSelectors.getSessionId(getState()),
        userId: userSessionSelectors.getUserId(getState()),
        itemNominal,
      },
    });

    dispatch(notificationCardActions.toggleNotificationCard(false));
    dispatch(actions.togglePurchaseLoading(false));

    if (selectors.getCurrentTab(getState()) === 'purchase-history') {
      dispatch(fetchPurchaseData());
    } else {
      dispatch(actions.changeCurrentTab('purchase-history'));
    }

    const { code } = res.data;
    const pagsgUrl = parse(PAGSEGURO_CHECKOUT, { code });
    const openPaymentInNewTab = () => window.open(pagsgUrl, '_blank');

    if (window.matchMedia('screen and (max-width: 768px)').matches) {
      openPaymentInNewTab();
    } else {
      const lightboxIsOpen = PagSeguroLightbox(
        { code },
        {
          // Success callback is not working properly. I'll leave it here for documentation
          success: (transactionCode) => {
            console.log(`transaction success: ${transactionCode}`);
            dispatch(fetchReportData());
          },
          abort: () => console.log('transaction abort'),
        },
      );

      if (!lightboxIsOpen) {
        openPaymentInNewTab();
      }
    }
  } catch (err) {
    console.error(err);
    dispatch(notificationCardActions.toggleNotificationCard(false));
    dispatch(actions.togglePurchaseLoading(false));
    const status = err.response && err.response.status;
    if (status === 401) {
      dispatch(handleUnauthorized(err, 'purchaseCredits'));
    }
  }
};

export const fetchCredits = (showLoading = true) => async (
  dispatch,
  getState,
) => {
  if (showLoading) {
    dispatch(actions.toggleFetchCredits(true));
    dispatch(actions.setCredits(null));
  }

  try {
    await refreshTokenIfNeeded(dispatch, getState);
  } catch (error) {
    dispatch(handleUnauthorized(error, 'fetchCredits'));
    return;
  }

  try {
    const firebaseToken = userSessionSelectors.getSessionId(getState());
    const userId = userSessionSelectors.getUserId(getState());

    const res = await api({
      method: 'POST',
      url: FETCH_CREDITS,
      data: {
        firebaseToken,
        userId,
      },
    });

    dispatch(actions.setCredits(fromJS(res.data.data)));
  } catch (err) {
    console.error(err);
    const status = err.response && err.response.status;
    if (status === 401) {
      dispatch(handleUnauthorized(err, 'fetchCredits'));
    }

    dispatch(actions.setCredits('UNKNOWN_ERROR'));
  } finally {
    if (showLoading) {
      dispatch(actions.toggleFetchCredits(false));
    }
  }
};

export const zipDownload = (requestId, timestamp) => async (dispatch) => {
  dispatch(actions.pushZipRequestId(requestId));

  try {
    const res = await axios({
      method: 'GET',
      responseType: 'blob',
      url: parse(ZIP_DOWNLOAD_URL, { requestId }),
    });
    FileSaver.saveAs(
      new Blob([res.data], { type: 'application/zip' }),
      `Arquivei Lite_${timestamp}.zip`,
    );
  } catch (err) {
    dispatch(notificationCardActions.setNotificationCardContext('try-again'));
    dispatch(notificationCardActions.toggleNotificationCard());
    console.error(err);
  } finally {
    dispatch(actions.deleteZipRequestId(requestId));
  }
};

export const documentMultiSearch = (
  accessKeys,
  successCallback,
  failureCallback,
) => async (dispatch, getState) => {
  dispatch(actions.toggleBatchLoading());

  let ip = userSessionSelectors.getUserIp(getState());
  let gaCookie = userSessionSelectors.getUserCookies(getState());

  if (ip === '' || gaCookie === '') {
    await dispatch(userSessionEffects.trackUserInfo());
    ip = userSessionSelectors.getUserIp(getState());
    gaCookie = userSessionSelectors.getUserCookies(getState());
  }

  try {
    await refreshTokenIfNeeded(dispatch, getState);
  } catch (error) {
    dispatch(handleUnauthorized(error, 'documentMultiSearch'));
    return;
  }

  try {
    await api({
      method: 'POST',
      data: {
        accessKeys,
        date: new Date().toLocaleString('pt-BR'),
        firebaseToken: userSessionSelectors.getSessionId(getState()),
        ip,
        userId: userSessionSelectors.getUserId(getState()),
        gaCookie,
      },
      url: DOCUMENT_MULTI_SEARCH_URL,
    });
    dispatch(
      notificationCardActions.setNotificationCardContext('batch-success'),
    );
    dispatch(notificationCardActions.toggleNotificationCard());

    if (typeof successCallback === 'function') {
      successCallback(generateUserEmailHash(), accessKeys.length);
    }
  } catch (err) {
    console.error(err);
    const status = err.response && err.response.status;
    let trackingError = 'generic-error';
    if (status === 400) {
      trackingError = 'invalid-keys';
      dispatch(
        notificationCardActions.setNotificationCardContext('batch-bad-format'),
      );
    } else if (status === 401) {
      trackingError = 'user-not-authorized';
      dispatch(
        notificationCardActions.setNotificationCardContext(
          'account-session-expired',
        ),
      );
    } else if (status === 403) {
      trackingError = 'limit-exceeded';
      dispatch(actions.setRemainingBatch(err.response.data.remaining));
      dispatch(
        notificationCardActions.setNotificationCardContext(
          'batch-limit-exceeded',
        ),
      );
    } else if (status === 429) {
      switch (err.response.data.code) {
        case 'LITE_FREE_TIER_ERROR':
          Router.push('/limit-error/global');
          break;
        case 'LITE_SEARCH_TOO_MANY_REQUESTS_PER_USER':
          Router.push('/limit-error/user');
          break;
        default:
          Router.push('/error');
      }
      return;
    } else {
      dispatch(notificationCardActions.setNotificationCardContext('try-again'));
    }

    dispatch(notificationCardActions.toggleNotificationCard(true));

    if (typeof failureCallback === 'function') {
      failureCallback(
        generateUserEmailHash(),
        trackingError,
        accessKeys.length,
      );
    }
  } finally {
    dispatch(fetchCredits());
    dispatch(actions.toggleBatchLoading());
  }
};
