import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { actions, effects } from 'store/single-key-query';

const WrapperGoogleRecaptcha = styled.div``;

class GoogleRecaptcha extends Component {
  async componentDidMount() {
    const { initializeGoogleRecaptcha } = this.props;
    await initializeGoogleRecaptcha();
  }

  componentWillUnmount() {
    const { clearGoogleRecaptcha } = this.props;
    clearGoogleRecaptcha();
  }

  render() {
    return <WrapperGoogleRecaptcha id="google-recaptcha" />;
  }
}

const mapDispatchToProps = (dispatch) => ({
  clearGoogleRecaptcha: () => dispatch(actions.setGoogleRecaptchaWidgetId('')),
  initializeGoogleRecaptcha: () =>
    dispatch(effects.initializeGoogleRecaptcha()),
});

export default connect(null, mapDispatchToProps)(GoogleRecaptcha);
