import React from 'react';
import { connect } from 'react-redux';
import Link from 'next/link';
import styled from 'styled-components';
import { ifProp } from 'styled-tools';

import { selectors as userSessionSelectors } from 'store/user-session';
import { actions as batchActions } from 'store/batch';

import { Logo } from 'layouts/components/atoms';

const WrapperLogo = styled.a`
  width: 268px;
  height: 40px;
  margin: 32px;
  cursor: pointer;

  @media screen and (max-width: ${ifProp('isLarge', '992px', '1366px')}) {
    width: 200px;
    height: 30px;
    margin: 37px 32px;
  }
`;

const LogoArea = ({ authorized, changeTabToBatchGenerator, ...props }) =>
  authorized ? (
    <WrapperLogo onClick={() => changeTabToBatchGenerator()} {...props}>
      <Logo />
    </WrapperLogo>
  ) : (
    <Link prefetch href="/">
      <WrapperLogo {...props}>
        <Logo />
      </WrapperLogo>
    </Link>
  );

const mapStateToProps = (state) => ({
  authorized: userSessionSelectors.getAuthorized(state),
});

const mapDispatchToProps = (dispatch) => ({
  changeTabToBatchGenerator: () =>
    dispatch(batchActions.changeCurrentTab('batch-generator')),
});

export default connect(mapStateToProps, mapDispatchToProps)(LogoArea);
